import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { CommonLoanView } from './common-loan';
import { StructuredUtilisationType } from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';

export type SequenceUtilisationView = {
  loanId: string;
  facilityId: string;
  sequenceId: string;
  version: number;
  state: StructuredLoanState;

  utilisation: StructuredUtilisationType;
  quantumCurrency: string | undefined;
  sequenceCurrency: string | undefined;

  // The sequence currency selection is a demo feature that is enabled only for
  // certain users.
  showSequenceCurrency: boolean;

  // Set if this is a new sequence that can be amended freely.
  amendNewSequence: boolean;

  commonView: CommonLoanView;
};

type SequenceUtilisationRequest = { loanId: string; sequenceId: string };

export const sequenceUtilisationRequestValidation = ObjectStripUnknown<SequenceUtilisationRequest>({
  loanId: TextStripEmpty.required(),
  sequenceId: TextStripEmpty.required(),
}).required();

export const sequenceUtilisationViewApi = defineApi<{
  body: SequenceUtilisationRequest;
  response: SequenceUtilisationView;
}>({
  url: 'structured-loans/sequence-utilisation-view',
  method: 'post',
});
