import { matchStringExhaustive } from '../utils/strings';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';

export type DocuSignMode = 'DEMO' | 'PRODUCTION' | 'STAGE';

export type DocuSignAccessToken = {
  accessToken: string;
  expiresIn: string;
  refreshToken: string;
  scope: string;
  tokenType: string;
};

export type DocuSignUserInfo = {
  sub: string; // the docusign user id
  email: string;
  accounts: {
    accountId: string;
    isDefault?: boolean;
    accountName: string;
    baseUri: string;
  }[];
  name: string;
  givenName: string;
  familyName: string;
  created: string;
};

/** The subset of DocuSign envelope events that this application is subscribing to.

    This is the full set of event types supported by DocuSign:

      envelope-sent: the envelope was sent.
      envelope-delivered: the envelope was delivered.
      envelope-completed: the envelope was completed.
      envelope-declined: the envelope was declined by the recipient.
      envelope-voided: the envelope was voided.
      envelope-resent: the envelope was resent.
      envelope-corrected: the envelope was corrected.
      envelope-purged: the envelope was purged.
      envelope-deleted: the envelope was deleted.
      envelope-discard: the envelope was discarded.
      *click-agreed: the click action was approved.
      *click-declined: the click action was declined.
      recipient-autoresponded: the recipient auto-responded.
      recipient-authenticationfailed: authentication failed for the recipient.
      recipient-finish-later:the recipient will complete the envelope later.
      custom value: define a custom event.
*/
export const docusignEnvelopeEventTypes = [
  'envelope-completed',
  'envelope-declined',
  'envelope-voided',
  'envelope-deleted',
  'envelope-discard',
] as const;

export type DocuSignEnvelopeEventType = (typeof docusignEnvelopeEventTypes)[number];

export type DocuSignEnvelopeEvent = {
  event: DocuSignEnvelopeEventType;
  generatedDateTime: string;
  data: {
    /** The envelope for which the event is for. */
    envelopeId: string;

    /** The account to which the envelope belongs. */
    accountId: string;

    /** The id of the DocuSign user owning the account. */
    userId: string;
  };
};

export type DocuSignEnvelopeStatus =
  | 'sent'
  | 'created'
  | 'envelope-completed'
  | 'envelope-declined'
  | 'envelope-voided'
  | 'envelope-deleted'
  | 'envelope-discard';

export const prettyEnvelopeStatus = (status: DocuSignEnvelopeStatus) =>
  matchStringExhaustive(status, {
    sent: () => 'Sent',
    created: () => 'Created',
    'envelope-completed': () => 'Completed',
    'envelope-declined': () => 'Declined',
    'envelope-voided': () => 'Voided',
    'envelope-deleted': () => 'Deleted',
    'envelope-discard': () => 'Discarded',
  });

export const docusignEnvelopeEventValidation = ObjectStripUnknown<DocuSignEnvelopeEvent>({
  event: TextStripEmpty.oneOf(docusignEnvelopeEventTypes).required(),
  generatedDateTime: TextStripEmpty.required(),
  data: ObjectStripUnknown({
    envelopeId: TextStripEmpty.required(),
    accountId: TextStripEmpty.required(),
    userId: TextStripEmpty.required(),
  }),
});

/** The Pulumi configuration of DocuSign */
export type PulumiDocuSignSetup = {
  /** Optional url for DocuSign webhook callbacks. If no url has been configured
      then no callbacks will be enabled for the envelopes. */
  callbackUrl?: string;
};

export const docusignSetupValidation = ObjectStripUnknown<PulumiDocuSignSetup>({
  callbackUrl: TextStripEmpty.optional(),
});

export type DocusignCompanyFeature = 'covenant-collection';

export type DocusignCompanyFeatures = {
  // The features for which the use of DocuSign has been enabled. Since the
  // object is stored as JSON and there are few onboarded companies on the
  // platform, it is not important whether this representation is future proof.
  features: DocusignCompanyFeature[];
};
