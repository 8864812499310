import { defineApi } from '../common/api-definition';
import { NextPastType } from '../common/NextPastType';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { ObjectStripUnknown } from '../validation/atoms';
import { literal, NonEmptyString, sumTypeRequired } from '../validation/base';
import { EventViewRequestValidation } from '../validation/EventViewRequestValidation';
import { BookingStatusType } from './BookingType';
import { EventViewRequest } from './EventViewRequest';
import { TransactionNextCommandType } from './NextCommandType';
import { TransactionType } from './TransactionType';

export type TransactionPageDataType = NextPastType<TransactionNextCommandType, TransactionType>;

export type TransactionView = NextTransactionView | PastTransactionView;

export type NextTransactionView = CommonTransactionView & {
  type: 'next';
  nextCommand: TransactionNextCommandType;
};

export type PastTransactionView = CommonTransactionView & {
  type: 'past';
  transaction: TransactionType;
};

export type CommonTransactionView = {
  loanId: string;
  sequenceId: string;
  bookingDataMap: {
    [bookingId: string]: {
      type: BookingStatusType;
      reference?: string;
      files?: RsUploadedFileType[];
    }[];
  };
  userCompanyId: string;
  companyNames: { [companyId: string]: string };
  currency: string;
  loanName: string;
  loanLevel: string;
  commitmentAmount: number;
  utilisationAmount: number;
  // True for bookings for which the custom amount can be set.
  allowCustomAmount: { [bookingId: string]: boolean };
  downloadUrls: { [fileKey: string]: { url: string } };
};

export type TransactionViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      sequenceId: string;
      transactionId: string;
    }
  | EventViewRequest;

export const transactionViewRequestValidation = sumTypeRequired('type', {
  'next-command-or-event': ObjectStripUnknown({
    type: literal('next-command-or-event'),
    loanId: NonEmptyString.required(),
    sequenceId: NonEmptyString.required(),
    transactionId: NonEmptyString.required(),
  }),
  event: EventViewRequestValidation,
});

export const transactionViewApi = defineApi<{
  body: TransactionViewRequest;
  response: TransactionView;
}>({
  url: 'structured-loans/get-transaction-view',
  method: 'post',
});
