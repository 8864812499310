import { matchStringExhaustive } from '../utils/strings';

export enum TemplatePurpose {
  covenant_certificate_templates = 'covenant_certificate_templates',
  payment_notice_templates = 'payment_notice_templates',
  rate_fixing_notice_templates = 'rate_fixing_notice_templates',
  facility_agreement_templates = 'facility_agreement_templates',
  nda_templates = 'nda_templates',
}

export const prettyTemplatePurpose = (templateType: TemplatePurpose) =>
  matchStringExhaustive(templateType, {
    covenant_certificate_templates: () => 'Compliance Certificate',
    payment_notice_templates: () => 'Payment Notice',
    rate_fixing_notice_templates: () => 'Rate Fixing Notice',
    facility_agreement_templates: () => 'Facility Agreement',
    nda_templates: () => 'NDA',
  });
