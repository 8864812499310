import { defineApi } from '../common/api-definition';
import { SuccessOrErrorType } from '../common/SuccessOrErrorType';
import { ObjectValidationIssueType } from '../common/ValidationIssueType';
import { StructuredAgreementType } from '../structured-loan/structured-agreement-type';
import { SLLifecycleRunLoan } from './LifecycleRunLoanType';
import { PartiesRunLoan } from './RunLoanType';

/**
 * These types are currently just flattened in the frontend, but we actually
 * want to make links to the pages where the errors are and show the errors
 * under the individual fields.
 */
export type StructuredLoanValidationBody = {
  lifecycle: SLLifecycleRunLoan;
  parties: PartiesRunLoan;
  'documents-and-securities': Pick<StructuredAgreementType, 'securities_and_guarantees'>;
  'distribution-and-accounts': Pick<StructuredAgreementType, 'distribution_restrictions' | 'bank_account_restrictions'>;
};

export type StructuredLoanValidationIssue = ObjectValidationIssueType<StructuredLoanValidationBody>;

export type StructuredLoanValidationIssueRequest = {
  loanId: string;
};

export const structuredLoanValidationIssueApi = defineApi<{
  body: StructuredLoanValidationIssueRequest;
  response: { issues?: StructuredLoanValidationIssue };
}>({
  url: 'structured-loans/validation-issue',
  method: 'post',
});

export type StructuredLoanSuccessOrError = SuccessOrErrorType<true, StructuredLoanValidationIssue | undefined>;
