import { defineApi } from '../common/api-definition';
import { CompanyTypeType } from '../common/CompanyTypeType';
import { CompanyInterface } from '../company/company.interface';
import { ObjectStripUnknown } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';
import { NocCompany } from './noc-company.type';

export type AdministrationNocFormView = {
  isPlatformAdmin: boolean;
  userCompanyType: CompanyTypeType;
  ownerCompanies: CompanyInterface[];

  /** The NOC that is being edited or undefined, if the page is creating a new NOC. */
  company?: NocCompany;
};

type AdministrationNocFormRequest = {
  /** The id of the NOC, if a NOC is being edited. */
  id?: string;
};

const administrationNocFormView = defineApi<{
  response: AdministrationNocFormView;
  body: AdministrationNocFormRequest;
}>({
  url: 'noc-companies/administration-noc-form-view',
  method: 'post',
});

const administrationNocFormViewValidation = ObjectStripUnknown<AdministrationNocFormRequest>({
  id: NonEmptyString.optional(),
}).required();

export const nocCompanyApi = {
  administrationNocFormView,
  administrationNocFormViewValidation,
};
