import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { CommonLoanView } from './common-loan';
import { StructuredAgreementType } from './structured-agreement-type';

export type DocumentsAndSecuritiesView = {
  loan: StructuredAgreementType;
  commonView: CommonLoanView;
};

export type DocumentsAndSecuritiesRequest = {
  loanId: string;
};

export const documentsAndSecuritiesValidation = ObjectStripUnknown<DocumentsAndSecuritiesRequest>({
  loanId: TextStripEmpty.required(),
}).required();

export const documentsAndSecuritiesApi = defineApi<{
  body: DocumentsAndSecuritiesRequest;
  response: DocumentsAndSecuritiesView;
}>({
  url: 'structured-loans/documents-and-securities-view',
  method: 'post',
});
