import { useField } from 'formik';
import { isString } from 'lodash';
import { useContext } from 'react';
import RsFieldIssues from './RsFieldIssues';
import { RsFormContext } from './RsForm';

export default function RsFieldError(props: { name: string; align?: 'left' | 'center' | 'right' }) {
  const { name, align } = props;
  const { getFieldIssues, ignoreRequireTouched } = useContext(RsFormContext);
  const [_, meta] = useField(name);
  const { error, touched } = meta;

  const isError = Boolean((touched || ignoreRequireTouched) && error);
  const fieldIssues = !touched && getFieldIssues ? getFieldIssues(name) : undefined;

  return (
    <>
      {!isError && !fieldIssues && <div className="error-msg"></div>}

      {isError && (
        <div className="error-msg">
          <p className={`help is-danger rs-no-margin${getTextAlignmentClass(align)}`}>{getMsg(error)}</p>
        </div>
      )}

      {fieldIssues && <RsFieldIssues fieldIssues={fieldIssues} />}
    </>
  );
}

type ObjectErrorType = { [name: string]: string | ObjectErrorType };

function getMsg(error: string | ObjectErrorType | undefined): string {
  if (error === undefined) return '';

  if (isString(error)) {
    return error;
  }

  return Object.values(error)
    .map((subError) => getMsg(subError))
    .join(', ');
}

/**
 * Gets text alignment class based on align parameter
 * https://bulma.io/documentation/helpers/typography-helpers/#alignment
 * @param align
 */
const getTextAlignmentClass = (align?: 'left' | 'center' | 'right') => {
  if (!align) return '';
  return ` has-text-${align === 'center' ? 'centered' : align}`;
};
