import * as Yup from 'yup';
import { literal, sumTypeRequired } from './base';
import { EventViewRequestValidation } from './EventViewRequestValidation';

export const InitiateDrawdownViewRequestValidation = sumTypeRequired('type', {
  'next-command-or-event': Yup.object({
    type: literal('next-command-or-event'),
    loanId: Yup.string().required(),
    sequenceId: Yup.string().required(),
    drawdownId: Yup.string().required(),
  }),
  event: EventViewRequestValidation,
});
