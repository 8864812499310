import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, RequiredArray } from '../validation/atoms';
import { enumStripEmpty } from '../validation/options';
import { DocusignCompanyFeature } from './docusign';

export type UpdateDocusignFeaturesRequest = {
  features: DocusignCompanyFeature[];
};

const featureValidation = enumStripEmpty<DocusignCompanyFeature>(['covenant-collection']);

export const updateDocusignFeaturesValidation = ObjectStripUnknown<UpdateDocusignFeaturesRequest>({
  features: RequiredArray(featureValidation.required()),
}).required();

export const updateDocusignFeaturesApi = defineApi<{
  body: UpdateDocusignFeaturesRequest;
}>({
  url: 'structured-loans/update-docusign-features',
  method: 'post',
});
