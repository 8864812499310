import { defineApi } from '../common/api-definition';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { ObjectStripUnknown, RequiredArray, RequiredMapOf, TextStripEmpty } from '../validation/atoms';
import { RsUploadedFileValidation } from '../validation/UploadedFileValidation';

export type CovenantCollectionDraftKey = {
  loanId: string;
  covenantId: string;
  checkDate: string;
};

export type CovenantCollectionDraft = {
  documents: CovenantCollectionDraftDocuments;
};

type CovenantCollectionDraftDocuments = {
  required: { [documentId: string]: RsUploadedFileType[] };
  additional: RsUploadedFileType[];
};

export type SaveCovenantCollectionRequest = {
  key: CovenantCollectionDraftKey;
  draft: CovenantCollectionDraft;
};

export const saveCovenantCollectionRequestValidation = ObjectStripUnknown<SaveCovenantCollectionRequest>({
  key: ObjectStripUnknown<CovenantCollectionDraftKey>({
    loanId: TextStripEmpty.required(),
    covenantId: TextStripEmpty.required(),
    checkDate: TextStripEmpty.required(),
  }).required(),
  draft: ObjectStripUnknown<CovenantCollectionDraft>({
    documents: ObjectStripUnknown<CovenantCollectionDraftDocuments>({
      required: RequiredMapOf(RequiredArray(RsUploadedFileValidation.required())),
      additional: RequiredArray(RsUploadedFileValidation.required()),
    }).required(),
  }).required(),
}).required();

export const saveCovenantCollectionApi = defineApi<{
  body: SaveCovenantCollectionRequest;
}>({
  url: 'structured-loans/save-covenant-collection',
  method: 'post',
});
