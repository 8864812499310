import { CurrencyType, formatDate, percentageFormat } from '@realstocks/types';
import { uuid4 } from '@sentry/utils';
import { isFunction, toUpper, upperCase } from 'lodash';
import { Fragment, MouseEventHandler } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { createPortal } from 'react-dom';
import ReactTooltip from 'react-tooltip';
import RsButton, { RsButtonPropsType } from '../../rs-elements/rs-button/RsButton';
import FormatMoney from '../../utils/FormatMoney';

type ColumnConfig = {
  grow?: number;
  sortable?: boolean;
  align?: 'left' | 'center' | 'right';
  default?: string;
  tag?: {
    [key: string]: string;
  };
  styles?: {
    [key: string]: string;
  };
};

type ColumnTextPolicy = ColumnConfig & {
  bold?: true;
};

type ColumnValid = ColumnConfig & {
  valid: string;
  invalid: string;
};

type ColumnConfigPercentage = ColumnConfig & {
  signed?: boolean;
  digits?: number;
};

type ColumnConfigTokens = ColumnConfig & {
  digits?: number;
  currency?: CurrencyType;
};

type ColumnConfigCustom = ColumnConfig & {
  align?: string;
};

type ColumnConfigDate = ColumnConfig & {
  dateFormat?: string;
};

type ColumnConfigCustomButton = ColumnConfig & {
  danger?: boolean;
  disabled?: boolean;
  showColumnName?: boolean;
  name?: string;
  buttonProps?: RsButtonPropsType;
};

type ColumnConfigCheckbox = ColumnConfig & {
  disabled?: boolean;
  showColumnName?: boolean;
  name?: string;
  checkedAll: boolean;
  check: (ev: any, row: any) => any;
  checkAll: (ev: any) => void;
};

export const textColumn = (name: string, selector: string | ((row: any) => string), config?: ColumnConfig) => {
  const column: any = {
    name,
    selector,
    sortable: config?.sortable || false,
    grow: config?.grow ?? 1,
    format: (row: any) => {
      let val = isFunction(selector)
        ? selector(row) ?? (config?.default || '-')
        : row[selector] ?? (config?.default || '-');

      if (Array.isArray(val)) {
        val = val.join(', ');
      }

      val = `${val}`; // sometimes it comes as number so make sure it's string.

      if (config?.tag) {
        const valueIdx = Object.keys(config.tag).indexOf(val);
        const classes = Object.values(config.tag);

        if (valueIdx >= 0) {
          val = <span className={`tag ${classes[valueIdx]}`}>{toUpper(val)}</span>;
        }
      }

      const uniqueIdentifier = uuid4();

      const targetEl = document.getElementById('react-tooltip-parent');

      return (
        <span>
          {targetEl &&
            createPortal(
              <ReactTooltip className={'reacttooltip'} id={uniqueIdentifier} effect="float">
                {val}
              </ReactTooltip>,
              targetEl
            )}

          <span data-tag="allowRowEvents" data-tip={val} data-for={uniqueIdentifier}>{`${val.slice(0, 45)}${
            val.length > 45 ? ' ...' : ''
          }`}</span>
        </span>
      );
    },
  } satisfies IDataTableColumn<any>;
  if (config?.align === 'right') {
    column.right = true;
  }
  if (config?.align === 'center') {
    column.center = true;
  }
  return column;
};

export const textColumnPolicy = (name: string, selector: string, config?: ColumnTextPolicy) => ({
  name,
  selector,
  sortable: true,
  grow: config?.grow !== undefined ? config.grow : 1,
  cell: (row: any) => (
    <p
      style={{
        margin: 0,
        padding: 0,
        whiteSpace: 'pre-line',

        fontWeight: config?.bold ? 'bold' : 'normal',
      }}
    >
      {row[selector]}
    </p>
  ),
});

export const textColumnValid = (name: string, selector: string | ((row: any) => string), config: ColumnValid) => ({
  name,
  selector,
  sortable: true,
  center: true,
  grow: config?.grow !== undefined ? config.grow : 1,
  cell: (row: any) => (
    <p
      style={{
        backgroundColor: isFunction(selector) ? selector(row) : row[selector] ? '#28a745' : '#aa2227',
        color: '#ffffff',
        padding: '0 6px',
        borderRadius: '2px',

        marginRight: '10px',
        textAlign: 'center',
        minWidth: '60px',
      }}
    >
      {isFunction(selector) ? selector(row) : row[selector] ? config.valid : config.invalid}
    </p>
  ),
});

type TagTextType = {
  value: string;
  color: 'is-success' | 'is-warning' | 'is-danger';
};
export const tagTextColumn = (
  name: string,
  selector: string | ((row: any) => string | boolean),
  config: ColumnConfig & {
    valid: TagTextType;
    invalid: TagTextType;
  }
) => ({
  name,
  selector,
  sortable: true,
  center: true,
  grow: config?.grow !== undefined ? config.grow : 1,
  cell: (row: any) => {
    const value = isFunction(selector) ? selector(row) : row[selector];

    return (
      <div title={value} className={`tag ${value === config.valid.value ? config.valid.color : config.invalid.color}`}>
        {upperCase(value)}
      </div>
    );
  },
});

export const textColumnSmall = (name: string, selector: string, config?: ColumnConfig) => ({
  name,
  selector,
  sortable: true,
  style: {},
  grow: config?.grow !== undefined ? config.grow : 1,
  format: (row: any) => row[selector] ?? '-',
  wrap: true,
});

export const customComponentColumn = (name: string, selector: string, config?: ColumnConfigCustom) => ({
  name,
  selector,
  sortable: true,
  grow: config?.grow || 1,
  cell: (row: any) => (
    <div
      data-tag="allowRowEvents"
      style={{
        width: '100%',
        textAlign: config?.align ? (config.align as any) : 'left',
        marginRight: config?.align === 'center' ? '10px' : 'initial',
      }}
    >
      {row[selector]}
    </div>
  ),
});

export const percentageColumn = (name: string, selector: string, config?: ColumnConfigPercentage) => ({
  name,
  selector,
  sortable: true,
  right: true,
  grow: config?.grow || 1,
  format: (row: any) => {
    if (row[selector] === '-') return '-';
    return row[selector] !== undefined
      ? percentageFormat(row[selector], {
          withPercentageSign: config?.signed ?? false,
        })
      : '-';
  },
});

export const moneyCurrencyColumn = (name: string, selector: string, config?: ColumnConfigTokens) => ({
  name,
  selector,
  sortable: true,
  right: true,
  grow: config?.grow || 1,
  cell: (row: any) => (
    <FormatMoney
      inputValue={row[selector]}
      currency={config?.currency || row.currency}
      totalCurrency={true}
      alternateValue="-"
    />
  ),
});

export const dateColumn = (name: string, selector: string | ((row: any) => string), config?: ColumnConfigDate) => ({
  name,
  selector,
  sortable: config?.sortable || false,
  right: true,
  grow: config?.grow || 1,
  format: (row: any) => {
    try {
      let dateValue;
      if (typeof selector === 'function') {
        dateValue = row[selector(row)];
      } else {
        dateValue = row[selector];
      }

      const isDottedDate = typeof dateValue === 'string' && dateValue.split('.').length > 2;
      if (isDottedDate) {
        dateValue = dateValue.split('.').reverse().join('-');
      }

      try {
        return formatDate(new Date(dateValue), config?.dateFormat);
      } catch (error) {
        return '-';
      }
    } catch (error) {
      return typeof selector === 'function' ? row[selector(row)] : row[selector] || 'n/a';
    }
  },
});

export const customButtonColumn = (name: string, selector: string, config?: ColumnConfigCustomButton) => {
  return {
    name: config?.showColumnName ? name : '',
    selector,
    sortable: false,
    button: true,
    right: true,
    style: { justifyContent: 'flex-end !important' },
    minWidth: '200px',
    grow: config?.grow || 1,
    cell: (row: any) => (
      <RsButton
        type={config?.danger ? 'danger' : 'primary'}
        inverted={true}
        onClick={row[selector]}
        disabled={config?.disabled}
      >
        {name}
      </RsButton>
    ),
  };
};

export function multiActionTableColumn<T extends { [key: string]: MouseEventHandler<HTMLButtonElement> }>(
  actions: {
    selector: keyof T;
    text: string;
    icon?: string;
    hidden?: (row: T) => boolean;
  }[],
  options: { columnName?: string; config?: ColumnConfigCustomButton }
): IDataTableColumn<T> {
  return {
    name: options.columnName || '',
    sortable: false,
    button: true,
    right: true,
    grow: options.config?.grow || 1,
    cell: (row: T) =>
      actions.map(({ selector, icon, text, hidden }) => {
        const uniqueIdentifier = uuid4();
        const isHidden = hidden?.(row) ?? false;

        if (isHidden) {
          return null;
        }

        return (
          <Fragment key={uniqueIdentifier}>
            <ReactTooltip id={uniqueIdentifier} effect="solid">
              {text}
            </ReactTooltip>
            <RsButton
              onClick={row[selector]}
              type={icon ? undefined : options.config?.danger ? 'danger' : 'primary'}
              inverted
              data-tip
              data-for={uniqueIdentifier}
              minWidth="20px"
              className={`is-text ${icon ? 'p-1 has-text-grey-darker' : ''}`}
              disabled={options.config?.disabled}
              {...options.config?.buttonProps}
            >
              {icon ? (
                <span className="icon is-medium">
                  <i className={`${icon} fa-lg`} />
                </span>
              ) : (
                text || ''
              )}
            </RsButton>
          </Fragment>
        );
      }),
  };
}

export function actionTableColumn<T extends { [key: string]: MouseEventHandler<HTMLButtonElement> }>(
  selector: keyof T,
  content: { text: string; icon?: string },
  options: { columnName?: string; config?: ColumnConfigCustomButton }
): IDataTableColumn<T> {
  return {
    name: options.columnName || '',
    sortable: false,
    button: true,
    right: true,
    minWidth: '150px',
    grow: options.config?.grow || 1,
    cell: (row: T) => (
      <RsButton
        onClick={row[selector]}
        type={content.icon ? undefined : options.config?.danger ? 'danger' : 'primary'}
        inverted
        title={content.text}
        minWidth="20px"
        className={`is-text`}
        disabled={options.config?.disabled}
        {...options.config?.buttonProps}
      >
        {content.icon ? (
          <span className="icon is-medium">
            <i className={`${content.icon} fa-lg`} />
          </span>
        ) : (
          content.text || ''
        )}
      </RsButton>
    ),
  };
}

export const buttonColumn = (
  name: string,
  selector: string,
  config?: ColumnConfig & {
    disabled?: boolean;
    showColumnName?: boolean;
    name?: string;
    allowCondition?: (row: any) => boolean;
  } & {
    type?: 'success' | 'primary' | 'danger';
    action?: (row: any) => any;
  }
) => {
  return {
    name: config?.showColumnName ? name : '',
    selector,
    sortable: false,
    button: true,
    right: true,
    style: { justifyContent: 'flex-end !important' },
    minWidth: config?.styles?.minWidth ?? '100px',
    grow: config?.grow || 1,
    cell: (row: any) => {
      if (!config?.action && !row.action) {
        return null;
      }

      if (config?.allowCondition) {
        if (!config?.allowCondition(row)) {
          return '';
        }
      }

      return (
        <RsButton
          type={config?.type || 'primary'}
          inverted={true}
          onClick={
            config?.action
              ? () => {
                  if (config.action) {
                    config.action(row);
                  }
                }
              : row.action
          }
          disabled={config?.disabled ? config.disabled : Boolean(row.disabled)}
        >
          {config?.showColumnName ? config.name : name}
        </RsButton>
      );
    },
  };
};

export const checkboxColumn = (selector: string, config?: ColumnConfigCheckbox) => {
  return {
    name: (
      <input
        name={`checkbox-all-${selector}`}
        className="is-clickable"
        type="checkbox"
        checked={config?.checkedAll}
        disabled={config?.disabled}
        onChange={(ev) => config?.checkAll(ev)}
        style={{ accentColor: '#5a709a' }}
      />
    ),
    selector,
    left: true,
    style: {
      justifyContent: 'flex-start !important',
      accentColor: '#5a709a',
    },
    grow: config?.grow || 1,
    cell: (row: any) => (
      <input
        name={`checkbox-${selector}`}
        className="is-clickable"
        type="checkbox"
        checked={row?.checked}
        disabled={config?.disabled}
        onChange={(ev) => config?.check(row, ev)}
      />
    ),
  };
};
