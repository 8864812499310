import { defineApi } from '../common/api-definition';
import { DocusignCompanyFeature, DocuSignMode } from './docusign';

export type CompanyDocusignView = {
  setup?: {
    mode: DocuSignMode;
    clientId: string;
  };
  token?: {
    createdAt: string;
    updatedAt: string;
  };

  features: Partial<Record<DocusignCompanyFeature, true>>;
};

export const companyDocusignApi = defineApi<{
  response: CompanyDocusignView;
}>({
  url: 'structured-loans/company-docusign-view',
  method: 'post',
});
