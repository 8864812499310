import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';

export type DownloadComplianceCertificateEnvelopeRequest = {
  /** The envelope to download. */
  envelopeId: string;
};

export const downloadComplianceCertificateEnvelopeValidation =
  ObjectStripUnknown<DownloadComplianceCertificateEnvelopeRequest>({
    envelopeId: TextStripEmpty.required(),
  }).required();

export type DownloadComplianceCertificateEnvelopeResponse = { url: string };

export const downloadComplianceCertificateEnvelopeApi = defineApi<{
  body: DownloadComplianceCertificateEnvelopeRequest;
  response: DownloadComplianceCertificateEnvelopeResponse;
}>({
  url: 'structured-loans/download-compliance-certificate',
  method: 'post',
});
