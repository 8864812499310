import { InterestPeriodAlignmentType } from '../loan/InterestPeriodAlignmentType';
import { LceNarrow } from '../utils/lce-discriminated-unions';
import { ObjectStripUnknown, TextStripEmpty } from './atoms';
import { literal, sumTypeOptional } from './base';

export const SLInterestPeriodAlignmentValidation = sumTypeOptional('type', {
  utilisation: ObjectStripUnknown<LceNarrow<InterestPeriodAlignmentType, 'utilisation'>>({
    type: literal('utilisation'),
  }),
  calendar: ObjectStripUnknown<LceNarrow<InterestPeriodAlignmentType, 'calendar'>>({ type: literal('calendar') }),
  custom: ObjectStripUnknown<LceNarrow<InterestPeriodAlignmentType, 'custom'>>({
    type: literal('custom'),
    date: TextStripEmpty,
  }),
});
