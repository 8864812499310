import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { CommonLoanView } from './common-loan';
import { StructuredAgreementType } from './structured-agreement-type';

export type FeesAndHedgingView = {
  hasConstructionTranche: boolean;
  loan: StructuredAgreementType;
  commonView: CommonLoanView;
};

export type FeesAndHedgingRequest = {
  loanId: string;
};

export const feesAndHedgingValidation = ObjectStripUnknown<FeesAndHedgingRequest>({
  loanId: TextStripEmpty.required(),
}).required();

export const feesAndHedgingApi = defineApi<{
  body: FeesAndHedgingRequest;
  response: FeesAndHedgingView;
}>({
  url: 'structured-loans/fees-and-hedging-view',
  method: 'post',
});
