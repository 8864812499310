import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { CommonLoanView } from './common-loan';
import { StructuredAgreementType } from './structured-agreement-type';
import { LoanParties } from './structured-loan-parties-type';
import { StructuredStartConfigurationType } from './structured-start-configuration-type';

export type StartNotificationsView = {
  loan: StructuredAgreementType;
  startConfiguration: StructuredStartConfigurationType;
  parties: LoanParties;
  commonView: CommonLoanView;
};

export type StartNotificationsRequest = {
  loanId: string;
};

export const startNotificationsValidation = ObjectStripUnknown<StartNotificationsRequest>({
  loanId: TextStripEmpty.required(),
}).required();

export const startNotificationsApi = defineApi<{
  body: StartNotificationsRequest;
  response: StartNotificationsView;
}>({
  url: 'structured-loans/start-custom-notifications-view',
  method: 'post',
});
