import { defineApi } from '../common/api-definition';
import { BaseRatePayload } from '../loan/BaseRatePayload';
import { BaseRateType } from '../loan/BaseRateType';
import { ObjectStripUnknown } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';
import { LoanStructureIdentification } from './structured-loan-type';

export type BaseRateView = {
  identification: LoanStructureIdentification;
  baseRate: BaseRateType;
  data?: BaseRatePayload;
  downloadUrls: { [fileKey: string]: { url: string } } | undefined;
};

type BaseRateViewRequest = {
  loanId: string;
  eventId: string;
};

export const baseRateApi = {
  baseRateView: defineApi<{ body: BaseRateViewRequest; response: BaseRateView }>({
    url: 'base-rates/base-rate-view',
    method: 'post',
  }),
  baseRateViewSchema: ObjectStripUnknown<BaseRateViewRequest>({
    loanId: NonEmptyString.required(),
    eventId: NonEmptyString.required(),
  }).required(),
};
