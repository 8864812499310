import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { enumStripEmpty } from '../validation/options';
import { DocuSignMode } from './docusign';

export type UpdateDocusignSetupRequest = {
  mode: DocuSignMode;
  clientId: string;

  // No object means keep the existing value. Otherwise overwrite the existing
  // value.
  secretKey?: { value: string };
  connectKey?: { value: string | undefined };
};

export const updateDocusignSetupValidation = ObjectStripUnknown<UpdateDocusignSetupRequest>({
  mode: enumStripEmpty<DocuSignMode>(['DEMO', 'STAGE', 'PRODUCTION']),
  clientId: TextStripEmpty.required(),
  secretKey: ObjectStripUnknown({
    value: TextStripEmpty.required(),
  }).optional(),
  connectKey: ObjectStripUnknown({
    value: TextStripEmpty.optional(),
  }).optional(),
}).required();

export const updateDocusignSetupApi = defineApi<{
  body: UpdateDocusignSetupRequest;
}>({
  url: 'structured-loans/update-docusign-setup',
  method: 'post',
});
