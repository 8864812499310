import { array, string } from 'yup';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { literal, sumTypeRequired } from '../validation/base';

/**
 * Added securities and guarantees.
 *
 * Each type should only be added once - at least one customer
 * describes them like that.
 */
export type SecuritiesAndGuarantees = Partial<Record<SecurityOrGuaranteeType, SecurityOrGuarantee>>;

/**
 * Defines the ordering in the UI
 */
export const securityOrGuaranteeTypes = [
  'shares',
  'property',
  'receivables',
  'bankAccounts',
  'fundGuarantees',
  'contracts',
  'hedging',
  'other',
] as const;
export type SecurityOrGuaranteeType = (typeof securityOrGuaranteeTypes)[number];
export const prettySecurityOrGuaranteeType = {
  shares: 'Shares',
  bankAccounts: 'Bank Accounts',
  contracts: 'Contracts',
  fundGuarantees: 'Fund Guarantees',
  hedging: 'Hedging',
  other: 'Other',
  property: 'Property',
  receivables: 'Receivables',
} satisfies Record<SecurityOrGuaranteeType, string>;

/**
 * Defines the ordering in the UI
 */
export const securityOrGuaranteeWhens = ['yes', 'no', 'dependent'] as const;
export type SecurityOrGuaranteeWhen = (typeof securityOrGuaranteeWhens)[number];
export const prettySecurityOrGuaranteeWhen = {
  yes: 'Yes',
  no: 'No',
  dependent: 'Dependent',
} satisfies Record<SecurityOrGuaranteeWhen, string>;

export type CrossCollateralizedOption =
  | { tag: 'fund' }
  | { tag: 'facility'; id: string }
  | { tag: 'property'; id: string };

/**
 * Securities and guarantees are collateral if the loan defaults.
 */
export type SecurityOrGuarantee = {
  /**
   * Yes, no, 'dependent', or undefined.
   *
   * Yes and 'dependent' means that it is included. With 'dependent', description should
   * be provided. No means it's not included. Undefined means it's not considered.
   *
   * It is important to our customers to be able to distinguish between
   * 'the user has considered this and it's a no' and 'the user has not considered this'.
   */
  when?: SecurityOrGuaranteeWhen;

  /**
   * References to other entities in the loan that are part of the collateralization.
   *
   * Options are facility legal entities (SPVs), properties, and the loan fund.
   */
  crossCollateralized: CrossCollateralizedOption[];

  /**
   * Description of the security or guarantee. Often a section copied from the loan
   * agreement. Mandatory when `when` is 'dependent'.
   */
  description?: string;
};

/**
 * Schema for updates of cross collateralized. Remark that this is not necessarily enough to run the loan.
 */
export const crossCollateralizedSchema = sumTypeRequired('tag', {
  fund: ObjectStripUnknown({ tag: literal('fund').required() }),
  facility: ObjectStripUnknown({ tag: literal('facility').required(), id: string().required() }),
  property: ObjectStripUnknown({
    tag: literal('property').required(),
    id: string().required(),
  }),
});

/**
 * Schema for updates of securities or guarantees. Remark that this is not necessarily enough to run the loan.
 */
export const securityOrGuaranteeSchema = ObjectStripUnknown<SecurityOrGuarantee>({
  crossCollateralized: array(crossCollateralizedSchema).default([]).test('isArray', 'Must be an array', Array.isArray),
  description: TextStripEmpty.optional(),
  when: string().oneOf(securityOrGuaranteeWhens).optional(),
}).required();

/**
 * Schema for updates of securities and guarantees. Remark that this is not necessarily enough to run the loan.
 */
export const securitiesAndGuaranteesSchema = ObjectStripUnknown<SecuritiesAndGuarantees>(
  Object.fromEntries(securityOrGuaranteeTypes.map(sog => [sog, securityOrGuaranteeSchema.optional()]))
).required();
