/**
 * A helper to match on just a string union.
 */
export function matchString<K extends string, A, F extends K = K>(
  v: K,
  cases: { [P in F]: (c: P) => A }
): K extends F ? A : A | undefined {
  return cases[v as unknown as F]?.(v as unknown as F) as unknown as any;
}

/**
 * A helper to match on just a string union. Will error if any cases are missing.
 */
export function matchStringExhaustive<K extends string, A>(v: K, cases: { [P in K]: (c: P) => A }): A {
  return cases[v]?.(v);
}

/**
 * Check if a string has anything in it.
 *
 * The return value is not completely correct, the string could contain whitespace, but for the way this
 * function is used it is very helpful to have even partially correct types.
 */
export const isNullOrEmpty = (s: string | null | undefined): s is null | undefined | '' =>
  s == null || s.trim().length === 0;
