import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { DocumentAssemblyType } from '../document-assembly/DocumentAssemblyType';
import { ObjectStripUnknown } from '../validation/atoms';

export type DownloadComplianceCertificatePreviewRequest = {
  format: 'word' | 'pdf';
  documentAssembly: DocumentAssemblyType;
};

export const downloadComplianceCertificatePreviewValidation =
  ObjectStripUnknown<DownloadComplianceCertificatePreviewRequest>({
    format: Yup.string().oneOf(['word', 'pdf']).required(),

    // The object is big and the request is forwarded to the document assembler.
    // Therefore no validation is done.
    documentAssembly: Yup.object({}).required() as unknown as Yup.Schema<DocumentAssemblyType>,
  }).required();

export type DownloadComplianceCertificatePreviewResponse = { url: string };

export const downloadComplianceCertificatePreviewApi = defineApi<{
  body: DownloadComplianceCertificatePreviewRequest;
  response: DownloadComplianceCertificatePreviewResponse;
}>({
  url: 'structured-loans/download-compliance-certificate-preview',
  method: 'post',
});
