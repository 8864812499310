import { keys } from '../utils/type-utils';

/**
   Interest period basis

   For different definitions of the interest period basis see:
   https://docs.oracle.com/en/industries/financial-services/banking-corporate-lending/14.7.1.0.0/clbil/specifying_the_interest_period_basis.html
*/
export type LCEInterestPeriodBasis =
  /** The value date is equal to end-date + 1 day.

      This is the default interest period basis definition.

      This option is called *Including the From Date* in the Oracle reference above.
  */
  | 'end-date-before-value-date'

  /** The value date is equal to end-date + 1 day, except for the maturity where
      termination-end-date = termination-value-date.

      This option is does not have corresponding definition in the Oracle
      reference, but is named *Including the From Date & Include Maturity Date* in the UI.
  */
  | 'end-date-before-value-date-except-maturity'

  /** The end date is equal to the value date.

      This option is called *Including both From and To Dates* in in the Oracle reference above.
   */
  | 'end-date-at-value-date';

const interestPeriodBasisLabels: Record<LCEInterestPeriodBasis, string> = {
  'end-date-before-value-date': 'Including the From Date',
  'end-date-before-value-date-except-maturity': 'Including the From Date & the Maturity Date',
  'end-date-at-value-date': 'Including both From and To Dates',
};

export const prettyInterestPeriodBasis = (basis: LCEInterestPeriodBasis) => interestPeriodBasisLabels[basis];

export const lceInterestPeriodBasisValues = keys(interestPeriodBasisLabels);
