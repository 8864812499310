import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';
import { findIndex, isArray } from 'lodash';
import React, { CSSProperties, useContext, useEffect, useRef, useState } from 'react';
import Select, { ActionMeta } from 'react-select';
import { SelectComponentsProps } from 'react-select/src/Select';
import RsInfo from '../../rs-elements/rs-info/RsInfo';
import RsLoading from '../../rs-loading/RsLoading';
import { RsLabel } from '../rs-label/RsLabel';
import RsFieldError from '../RsFieldError';
import { rsFieldDisabledClass, RsFormContext } from '../RsForm';
import { getInputErrorClass } from '../utils/form-helpers';
import './RsSelect.scss';

export type RsSelectType = { label: string; value: string };

export const listWithEmptyValue = (values: any[], valueKey: string = 'value', labelKey: string = 'label') => [
  { [valueKey]: '', [labelKey]: 'None' },
  ...values,
];

type Props = {
  label?: string | React.ReactNode;
  hideError?: boolean;
};

type FieldDataType = { [key: string]: string };

const RsSelect = <T,>(props: Props & InputProps<T>) => {
  const [_field, meta, _actions] = useField(props.name);
  const { isValidating: isValidatingGlobal } = useFormikContext();
  const { mode, showValidatingText, getFieldIssues, ignoreRequireTouched } = useContext(RsFormContext);

  const [isValidating, setIsValidating] = useState<boolean>(false);

  useEffect(() => {
    if (!isValidatingGlobal) {
      setIsValidating(false);
    }
  }, [isValidatingGlobal]);

  const errorClass = getInputErrorClass(props.name, meta, ignoreRequireTouched, getFieldIssues);

  return (
    <div
      className={clsx({
        'rs-field rs-field-select': true,
        [rsFieldDisabledClass]: props.disabled,
        [errorClass]: true,
      })}
    >
      <div className="rs-field-content">
        {props.label && <RsLabel {...props} label={props.label} />}

        <RsSelectInput {...props} setIsValidating={setIsValidating} />
      </div>
      {!props.hideError && mode === 'edit' && <RsFieldError name={props.name} />}
      {showValidatingText && isValidating && (
        <span style={{ textAlign: 'right', width: '100%', fontSize: '0.6rem', color: '#5a709a' }}>VALIDATING...</span>
      )}
    </div>
  );
};

export default React.memo(RsSelect) as typeof RsSelect;

type InputProps<T> = {
  id?: string;
  name: string;
  multiple?: boolean;
  data: readonly any[] | undefined;
  datalabelkey?: string;
  datavaluekey?: string;
  defaultOptionLabel?: string;
  onChange?: (value: T[] | T | '') => void;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
  hideSelectedOptions?: boolean;
  isOptionDisabled?: (value: T[] | T | '') => boolean;
  onBlurCallback?: (field: string, value: any) => void;
  children?: React.ReactNode;
  isClearable?: boolean;
  formatOptionLabel?: SelectComponentsProps['formatOptionLabel'];
};

const RsSelectInputInner = <T,>({
  setIsValidating,
  className,
  ...props
}: InputProps<T> & { setIsValidating?: (_: boolean) => void; className?: string }) => {
  const [field, _meta, actions] = useField(props.name);
  const { setFieldValue, isValidating: isValidatingGlobal } = useFormikContext();
  const { loading } = props;
  const { mode, loading: formLoading } = useContext(RsFormContext);

  const selectRef = useRef<Select>(null);

  const labelKey = props.datalabelkey ? props.datalabelkey : 'label';
  const valueKey = props.datavaluekey ? props.datavaluekey : 'value';

  useEffect(() => {
    if (!isValidatingGlobal) {
      setIsValidating?.(false);
    }
  }, [isValidatingGlobal]);

  const customStyles = {
    control: (base: CSSProperties) => ({
      ...base,
      minHeight: 38,
    }),
    menuPortal: (base: CSSProperties) => ({ ...base, zIndex: 9999 }),
    menu: (base: CSSProperties) => ({ ...base, zIndex: 9999 }),
    option: (base: CSSProperties, item: { isDisabled?: boolean }) => {
      if (item.isDisabled)
        return {
          ...base,
          backgroundColor: 'white',
          fontWeight: 'bold',
        };
      return base;
    },
  };

  const handleBlur = () => {
    if (props.onBlurCallback) {
      props.onBlurCallback(field.name, field.value);
    }
    setTimeout(() => {
      actions.setTouched(true, true);
      setIsValidating?.(true);
    }, 100);
  };

  let value = '';

  if (field.value !== null && props.data && props.data.length) {
    value = props.multiple
      ? props.data.filter((option: FieldDataType) => {
          return props.multiple
            ? findIndex(field.value, (value: string) => value === option[valueKey]) >= 0
            : option[valueKey] === field.value;
        })
      : props.data.find((o: FieldDataType) => o[valueKey] === field.value) || '';
  }

  return (
    <div
      className={clsx(
        {
          'field-wrapper': true,
          'rs-clearable': props.isClearable ?? true,
          loading: loading,
          'full-width': props.fullWidth,
          'required-input': props.required,
        },
        className
      )}
    >
      {(loading || formLoading) && mode !== 'view' ? (
        <RsLoading height="34px" />
      ) : (
        <>
          {mode === 'edit' ? (
            <Select
              isMulti={props.multiple === true}
              isDisabled={props.disabled}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              className="rs-select-container"
              classNamePrefix="rs-select"
              isClearable={props.isClearable ?? true}
              ref={selectRef}
              options={props.data}
              blurInputOnSelect={true}
              name={field.name}
              placeholder={props.defaultOptionLabel ? props.defaultOptionLabel : 'Select...'}
              getOptionLabel={(o: FieldDataType) => o[labelKey]}
              getOptionValue={(o: FieldDataType) => o[valueKey]}
              formatOptionLabel={props.formatOptionLabel}
              styles={customStyles}
              value={value}
              onChange={(option: any, meta: ActionMeta<any>) => {
                let selectedValue: T[] | T | '' = '';

                if (props.multiple) {
                  selectedValue = option && isArray(option) ? option.map((o: any) => o[valueKey]) : [];
                } else {
                  selectedValue = option ? option[valueKey] : '';
                }
                setFieldValue(field.name, selectedValue);

                if (props.onChange) {
                  props.onChange(selectedValue);
                }

                // blur after clear
                const { action } = meta;
                if (action === 'clear') {
                  setTimeout(() => {
                    if (selectRef.current) {
                      const el = selectRef.current;
                      el.select.blur();
                    }
                  }, 100);
                }
              }}
              onBlur={handleBlur}
              hideSelectedOptions={props.hideSelectedOptions}
              isOptionDisabled={props.isOptionDisabled}
            />
          ) : field.value ? (
            <p className="has-text-weight-bold rs-no-margin">
              <span>
                {props.multiple && isArray(field.value)
                  ? field.value
                      .map((val) => {
                        const foundData = props.data?.find((el: any) => el[valueKey] === val);
                        return foundData ? foundData[labelKey] : '';
                      })
                      .join(', ')
                  : props.data?.find((el: any) => el[valueKey] === field.value)?.[labelKey]}
              </span>
            </p>
          ) : formLoading || loading ? (
            <RsLoading height="22px" />
          ) : (
            <p className="has-text-weight-bold rs-no-margin">-</p>
          )}

          {props.children && (
            <RsInfo id={`${props.name || props.id}_${Math.random()}`} type="dark">
              {props.children}
            </RsInfo>
          )}
        </>
      )}
    </div>
  );
};

export const RsSelectInput = React.memo(RsSelectInputInner) as typeof RsSelectInputInner;
