import { string } from 'yup';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';

/**
 * Restrictions on what the borrower is allowed to do with the loan.
 */
export type DistributionRestrictions = Partial<Record<DistributionRestrictionType, DistributionRestriction>>;

/**
 * Also defines the order in the UI
 */
export const distributionRestrictionTypes = [
  'fundFees',
  'managementFees',
  'dividends',
  'intraGroupLoanInterests',
  'intraGroupLoanPrincipal',
  'intraGroupLoanConversion',
  'shareholderLoanInterests',
  'shareholderLoanPrincipal',
  'shareholderLoanConversion',
  'releaseApprovalProcess',
  'other',
] as const;
export type DistributionRestrictionType = (typeof distributionRestrictionTypes)[number];
export const prettyDistributionRestrictionType = {
  dividends: 'Dividends',
  fundFees: 'Fund Fees',
  intraGroupLoanConversion: 'Intra-Group Loan Conversion',
  intraGroupLoanInterests: 'Intra-Group Loan Interests',
  intraGroupLoanPrincipal: 'Intra-Group Loan Principal',
  managementFees: 'Management Fees',
  other: 'Other',
  releaseApprovalProcess: 'Release Approval Process',
  shareholderLoanConversion: 'Shareholder Loan Conversion',
  shareholderLoanInterests: 'Shareholder Loan Interests',
  shareholderLoanPrincipal: 'Shareholder Loan Principal',
} satisfies Record<DistributionRestrictionType, string>;

/**
 * Also defines the order in the UI
 */
export const distributionRestrictionOptions = ['no', 'always', 'softBreach', 'hardBreach', 'other'] as const;
export type DistributionRestrictionOption = (typeof distributionRestrictionOptions)[number];
export const prettyDistributionRestrictionOption = {
  always: 'Always',
  hardBreach: 'Hard Breach',
  no: 'No',
  other: 'Other',
  softBreach: 'Soft Breach',
} satisfies Record<DistributionRestrictionOption, string>;

/**
 * `restricted` should not be empty in running loans (don't add it to the array then).
 *
 * If `restricted` is `'other'`, `description` shouldn't be empty in running loans (that's an error).
 */
export type DistributionRestriction = {
  restricted?: DistributionRestrictionOption;
  description?: string;
};

const distributionRestrictionSchema = ObjectStripUnknown<DistributionRestriction>({
  description: TextStripEmpty.optional(),
  restricted: string().oneOf(distributionRestrictionOptions).optional(),
});

export const distributionRestrictionsSchema = ObjectStripUnknown<DistributionRestrictions>(
  Object.fromEntries(distributionRestrictionTypes.map(drt => [drt, distributionRestrictionSchema.optional()]))
);
