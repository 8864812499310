import { ObjectStripUnknown, TextStripEmpty } from './atoms';
import { literal, sumTypeRequired } from './base';
import { EventViewRequestValidation } from './EventViewRequestValidation';

export const InitiateUtilisationViewRequestValidation = sumTypeRequired('type', {
  'next-command-or-event': ObjectStripUnknown({
    type: literal('next-command-or-event'),
    loanId: TextStripEmpty.required(),
    sequenceId: TextStripEmpty.required(),
  }),
  event: EventViewRequestValidation,
});
