import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';

export type AuthorizeDocusignSetupRequest = {
  /** The front end location to redirect to after authentication. */
  authorizationUrl: string;

  /** The front end location to redirect to after completion of the action. */
  currentUrl: string;

  /** Identifier for the create envelope action. This id will be set in the
          search params of 'currentUrl' if the action is executed after
          authorization redirect to DocuSign.
      */
  actionId: string;
};

export const authorizeDocusignSetupValidation = ObjectStripUnknown<AuthorizeDocusignSetupRequest>({
  actionId: TextStripEmpty.required(),
  authorizationUrl: TextStripEmpty.required(),
  currentUrl: TextStripEmpty.required(),
}).required();

export type AuthorizeDocusignSetupResponse = { type: 'redirect'; url: string } | { type: 'success' };

export const authorizeDocusignSetupApi = defineApi<{
  body: AuthorizeDocusignSetupRequest;
  response: AuthorizeDocusignSetupResponse;
}>({
  url: 'structured-loans/authorize-docusign-setup',
  method: 'post',
});
