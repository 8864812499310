export const RsApiEndpoints = {
  platformAdmin: {
    paymentNoticeSettings: '/payment-notice-settings',
    paymentNoticeTemplates: '/payment-notice-templates',
    paymentNoticeTemplateUrl: '/payment-notice-template-url',
    rateFixingNoticeSettings: '/rate-fixing-notice-settings',
    rateFixingNoticeTemplates: '/rate-fixing-notice-templates',
    rateFixingNoticeTemplateUrl: '/rate-fixing-notice-template-url',
  },
  company: {
    new: `/company/new`,
    getDetails: `/company/get-company-details`,
    companyUsers: `/company/company-users`,
    offerings: '/company/offerings',
    save: {
      companyInformation: `/company/save-company-information`,
      representatives: `/company/save-representatives`,
      ubo: `/company/save-ubo`,
      documents: `/company/save-documents`,
      confirmation: `/company/confirmation`,
    },
    realstocksAdmin: {
      getCompanyDetails: '/realstocks-admin/get-company-details',
    },
    lookup: '/company/lookup',
    getAllKyc: '/company/get-all-kyc',
    updateKycStatus: '/company/update-kyc-status',
    downloadFiles: '/company/files/download',
    getCompanyLabels: '/company/labels',
    checkDeleteLabel: (labelId: string) => `/company/labels/${labelId}/references`,
    updatePlaformInteractions: '/company/update-platform-interactions',
  },
  loans: {
    getLoansSavedFilters: '/loans/get-saved-filters',
    saveFilter: '/loans/save-filter',
    downloadFiles: '/loans/download-files',
  },
  user: {
    createEidTransaction: '/users/eid/create-transaction',
    linkBankEidToAccount: '/users/eid/link-bank-eid-to-account',
    loginWithBankeid: '/eid-login',
    resetTemporaryPassword: '/users/reset-temporary-password',
    getBankByUserEmail: '/users/eid/get-bank-by-user-email',
    getCompanies: '/user/get-companies',
    adminGetAllUsers: '/users/admin-get-all',
    adminToggleUserAccount: '/users/admin-toggle-user-account',
  },
  notifications: {
    getOutstandingNotifications: '/notifications/outstanding',
    getAllNotifications: '/notifications/all',
    clearNotification: '/notifications/clear',
    test: '/notifications/test',
  },
  facilityAgreement: {
    rejectAttributes: '/fa/rejectAttributes',
    acceptAttributes: '/fa/acceptAttributes',
    acceptRequest: '/fa/acceptRequest',
    cancelFacilityAgreement: '/fa/cancelFacilityAgreement',
    createFacilityAgreement: '/fa/createFacilityAgreement',
    saveAttributes: '/fa/saveAttributes',
    getAllCompanies: '/fa/getAllCompanies',
    getAllReferencedUsers: '/fa/getAllReferencedUsers',
    getAllUsers: '/fa/getAllUsers',
    getCovenantCertificateSettings: '/fa/getCovenantCertificateSettings',
    getDirectoryObjects: '/fa/getDirectoryObjects',
    getDocuSignConsentUrl: '/docusign/getDocuSignConsentUrl',
    getDocuSignIdentityVerification: '/docusign/getDocuSignIdentityVerification',
    getFacilityAgreement: '/fa/getFacilityAgreement',
    queryFacilityAgreements: {
      getForCompanies: '/fa/getCompaniesFacilityAgreements',
      getByIds: '/fa/getFacilityAgreements',
    },
    getSignedUrl: '/fa/getSignedUrl',
    getFaSigningUrl: '/fa-signing/getFaSigningUrl',
    getTransitions: '/fa/getTransitions',
    releaseForLenderQuotes: '/fa/releaseForLenderQuotes',
    releaseForSigning: '/fa-signing/releaseForSigning',
    restartFacilityAgreement: '/fa/restartFacilityAgreement',
    setCovenantCertificateSettings: '/fa/setCovenantCertificateSettings',
    setLeadLender: '/fa/setLeadLender',
    setAgent: '/fa/setAgent',
    setLenderQuotesIsOpen: '/fa/setLenderQuotesIsOpen',
    setShareAllocation: '/fa/setShareAllocation',
    setSigners: '/fa-signing/setSigners',
    setTemplate: '/fa/setTemplate',
    getNdaDocumentAssembly: '/fa/getNdaDocumentAssembly',
    updateNdaSetup: '/fa/updateNdaSetup',
    getNdaSigningUrl: '/fa-signing/getNdaSigningUrl',
    startNegotiation: '/fa/startNegotiation',
    startExecution: '/fa/startExecution',
    updateAgreement: '/fa-agreement/updateAgreement',
    updateAgreementComments: '/fa-agreement/updateAgreementComments',
    updateFaComments: '/fa/updateFaComments',
    acceptAgreement: '/fa-agreement/acceptAgreement',
    setAgreementLock: '/fa-agreement/setAgreementLock',
    getAgreementVersion: '/fa-agreement/getAgreementVersion',
    addDocuments: '/fa/addDocuments',
    updateDocumentCategory: '/fa/updateDocumentCategory',
    addDocumentCategory: '/fa/addDocumentCategory',
    openLegalTermNegotiation: '/fa/open-legal-term-negotiation',
    downloadFaFile: '/fa/files/get',
    saveFaRules: '/fa/saveFaRules',
  },
  contactForm: '/contact-form',
  chat: {
    getCompanyContacts: '/secure/get-company-contacts',
    getUsersInCompany: '/secure/get-users-in-company',
    getConversations: '/secure/get-conversations',
    sendMessage: '/secure/send-message',
    getMessages: '/secure/get-conversation-messages',
    updatePresenceStatus: '/secure/updatePresenceStatus',
    readMessage: '/secure/save-last-read-message',
    searchContacts: '/secure/search-contacts',
    readConversationReceipt: '/secure/read-conversation',
  },
  getTmpUploadSignedUrl: '/get-tmp-upload-signed-url',
  getTmpDownloadSignedUrl: '/get-tmp-download-signed-url',
  getFiltersOptions: '/get-filters-options',
  getRoutesAccessRights: '/get-routes-access-rights',
};
