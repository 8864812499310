import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';
import { CommonLoanView } from './common-loan';
import { SLPropertyDetails } from './SLPropertyType';
import { StructuredLoanState } from './structured-loan-state';

export type PropertyView = {
  loanId: string;
  facilityId: string;
  propertyId: string;
  state: StructuredLoanState;
  version: number;
  property: SLPropertyDetails;
  downloadUrls: { [fileKey: string]: { url: string } };
  commonView: CommonLoanView;
};

export type PropertyViewRequest = {
  loanId: string;
  facilityId: string;
  propertyId: string;
};

export const propertyViewRequestValidation = ObjectStripUnknown<PropertyViewRequest>({
  loanId: NonEmptyString.required(),
  facilityId: NonEmptyString.required(),
  propertyId: NonEmptyString.required(),
}).required();

export const propertyViewApi = defineApi<{
  body: PropertyViewRequest;
  response: PropertyView;
}>({
  url: 'structured-loans/property-view',
  method: 'post',
});
