import { keys } from '../utils/type-utils';

export type InterestPeriodType = 'monthly' | 'quarterly' | 'semi-yearly' | 'yearly';

export const AllInterestPeriodLabels: Record<InterestPeriodType, string> = {
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  'semi-yearly': 'Half-Yearly',
  yearly: 'Yearly',
} as const;

export function prettyInterestPeriod(period: InterestPeriodType) {
  return AllInterestPeriodLabels[period];
}

export const interestPeriodTypes = keys(AllInterestPeriodLabels);
