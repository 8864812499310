import { LoadedData } from '@realstocks/types';
import { LoanState } from '../../store';
import { LoanActions } from './loan-actions';

const initialState: LoanState = {
  loans: {},
  agreementPageMap: {},
  agreementMap: {},
  ndaAgreementMap: {},
  companies: null,
  companiesLoaded: false,
  users: [],
  usersLoaded: false,
  templates: [],
  templatesLoaded: false,
  identityVerificationLoaded: false,
};

function getLoadedDataRefresh<T>(data: LoadedData<T> | undefined): LoadedData<T> | undefined {
  if (!data) return undefined;
  return {
    isLoaded: false, // Mark data as no longer up to date, so that it will refresh.
    data: data.data,
  };
}

export const LoanReducer = (state: LoanState = initialState, action: LoanActions): LoanState => {
  switch (action.type) {
    case 'SET_LOAN': {
      const loans = { ...state.loans };

      const oldLoan = loans[action.loan.id];

      let newLoan = action.loan;
      if (oldLoan) {
        // Temporarily preserve old loaded data, so that pages are refreshed smoothly.
        newLoan = {
          ...newLoan,
          transitions: getLoadedDataRefresh(oldLoan.transitions),
          usersMap: getLoadedDataRefresh(oldLoan.usersMap),
        };
      }

      loans[action.loan.id] = newLoan;

      return {
        ...state,
        loans: loans,
      };
    }

    case 'SET_AGREEMENT': {
      switch (action.resource) {
        case 'agreement':
          return {
            ...state,
            agreementMap: {
              ...state.agreementMap,
              [action.template]: action.agreement,
            },
          };
        case 'ndaAgreement': {
          return {
            ...state,
            ndaAgreementMap: {
              ...state.ndaAgreementMap,
              [action.template]: action.agreement,
            },
          };
        }
      }
      return state;
    }

    case 'SET_COMPANIES': {
      return {
        ...state,
        companies: action.companies,
        companiesLoaded: true,
      };
    }
    case 'RESET_COMPANIES': {
      return {
        ...state,
        companies: [],
        companiesLoaded: false,
      };
    }
    case 'SET_ALL_USERS': {
      return {
        ...state,
        users: action.users,
        usersLoaded: true,
      };
    }
    case 'SET_TEMPLATES': {
      return {
        ...state,
        templates: action.templates,
        templatesLoaded: true,
      };
    }
    case 'RESET_TEMPLATES': {
      return {
        ...state,
        templates: [],
        templatesLoaded: false,
      };
    }
    case 'SET_ALL_REFERENCED_USERS': {
      const id = action.id;
      const loan = state.loans[id];
      if (!loan) return state;

      return {
        ...state,
        loans: {
          ...state.loans,
          [id]: {
            ...loan,
            usersMap: {
              isLoaded: true,
              data: action.users,
            },
          },
        },
      };
    }
    case 'SET_TRANSITIONS': {
      const id = action.id;
      const loan = state.loans[id];
      if (!loan) return state;

      const loans = { ...state.loans };
      loans[id] = {
        ...loan,
        transitions: {
          isLoaded: true,
          data: action.transitions,
        },
      };
      return {
        ...state,
        loans: loans,
      };
    }
    case 'SET_DOCUSIGN_IDENTITY_VERIFICATION': {
      return {
        ...state,
        identityVerification: action.identityVerification,
        identityVerificationLoaded: true,
      };
    }
    case 'SET_AGREEMENT_PAGE_VIEW': {
      return {
        ...state,
        agreementPageMap: {
          ...state.agreementPageMap,
          [action.loanId]: {
            ...state.agreementPageMap[action.loanId],
            pageView: action.pageView,
          },
        },
      };
    }
    case 'SET_AGREEMENT_SELECTED_SECTION': {
      return {
        ...state,
        agreementPageMap: {
          ...state.agreementPageMap,
          [action.loanId]: {
            ...state.agreementPageMap[action.loanId],
            selectedSection: action.selectedSection,
          },
        },
      };
    }
    case 'FACILITY_AGREEMENT_CHANGED': {
      const { id } = action.data;
      let newState = state;
      const loan = state.loans[id];

      if (loan) {
        const loans = { ...state.loans };
        const timestamp = Date.now();

        loans[id] = {
          ...loan,
          lastWebsocketUpdate: timestamp,
        };

        newState = {
          ...newState,
          loans: loans,
        };
      }

      return newState;
    }

    case 'RESET_ALL': {
      return {
        ...initialState,
      };
    }
  }

  return state;
};
