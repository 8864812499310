import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { LCELoggedCommandsMap } from '../loan/CommandType';
import { ObjectStripUnknown, RequiredArray } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';

export type LoggedCommandsRequest = {
  loanIds: string[];

  /** Mode for the fetching of the commands

     raw-commands: the commands are read directly from the database
     cached-commands: the commands are retrieved from the loan state cache
  */
  mode?: 'raw-commands' | 'cached-commands';
};

export const loggedCommandsRequestValidation = ObjectStripUnknown<LoggedCommandsRequest>({
  loanIds: RequiredArray(NonEmptyString.required()),
  mode: Yup.mixed().oneOf(['raw-commands', 'cached-commands']),
}).required();

export type LoggedCommandsResponse = {
  commands: LCELoggedCommandsMap;
};

export const loggedCommandsApi = defineApi<{
  body: LoggedCommandsRequest;
  response: LoggedCommandsResponse;
}>({
  url: 'platform-admin/logged-commands',
  method: 'post',
});
