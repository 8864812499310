import { defineApi } from '../common/api-definition';
import { CompanyInterface } from '../company/company.interface';

export type NewLoanView = {
  isPlatformAdmin: boolean;
  ownerCompanies: CompanyInterface[];
};

export const newLoanViewApi = defineApi<{
  response: NewLoanView;
}>({
  url: 'structured-loans/new-loan-view',
  method: 'post',
});
