import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';

// Values received from DocuSign upon succesful user login.
export type DocusignAuthorizedRequest = {
  code: string;
  state: string;
};

export const docusignAuthorizedValidation = ObjectStripUnknown<DocusignAuthorizedRequest>({
  code: TextStripEmpty.required(),
  state: TextStripEmpty.required(),
});

export const docusignAuthorizedApi = defineApi<{
  body: DocusignAuthorizedRequest;
  response: { url: string };
}>({
  url: 'structured-loans/docusign-authorized',
  method: 'post',
});
