import { defineApi } from '../common/api-definition';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { ObjectStripUnknown, RequiredArray, TextStripEmpty } from '../validation/atoms';
import { literal, RsFileValidation, sumTypeOptional, sumTypeRequired } from '../validation/base';

export type CreateComplianceCertificateEnvelopeRequest = {
  loanId: string;
  covenantId: string;
  checkDate: string;

  /** The signer of the envelope. */
  signer: EnvelopeSigner;

  /** The compliance certificate template being signed. The template can be undefined if
      the signing does not require the document assembly of a compliance certificate.
  */
  template?:
    | {
        // Use the template configured for the specific covenant report.
        type: 'covenant-default';
      }
    | {
        // Use a one-off file uploaded to the tmp folder.
        type: 'one-off';
        file: RsUploadedFileType;
      };

  /** Other documents to include in the envelope. The S3 file keys reference
      documents in the covenant collection draft. */
  otherDocuments: { fileKey: string }[];
};

type EnvelopeSigner = ResponsibleUserEnvelopeSigner | CustomEnvelopeSigner;

type ResponsibleUserEnvelopeSigner = {
  type: 'responsible-user';
  userId: string;
};

type CustomEnvelopeSigner = {
  type: 'custom';
  name: string;
  email: string;
};

const envelopeSignerValidation = sumTypeRequired('type', {
  'responsible-user': ObjectStripUnknown<ResponsibleUserEnvelopeSigner>({
    type: literal('responsible-user'),
    userId: TextStripEmpty.required(),
  }),
  custom: ObjectStripUnknown<CustomEnvelopeSigner>({
    type: literal('custom'),
    name: TextStripEmpty.required(),
    email: TextStripEmpty.required(),
  }),
});

export const createComplianceCertificateEnvelopeValidation =
  ObjectStripUnknown<CreateComplianceCertificateEnvelopeRequest>({
    loanId: TextStripEmpty.required(),
    covenantId: TextStripEmpty.required(),
    checkDate: TextStripEmpty.required(),
    signer: envelopeSignerValidation,
    template: sumTypeOptional('type', {
      'covenant-default': ObjectStripUnknown({
        type: literal('covenant-default'),
      }),
      'one-off': ObjectStripUnknown({
        type: literal('one-off'),
        file: RsFileValidation.required(),
      }),
    }),
    otherDocuments: RequiredArray(
      ObjectStripUnknown({
        fileKey: TextStripEmpty.required(),
      }).required()
    ),
  }).required();

export type CreateComplianceCertificateEnvelopeResponse = { type: 'success' };

export const createComplianceCertificateEnvelopeApi = defineApi<{
  body: CreateComplianceCertificateEnvelopeRequest;
  response: CreateComplianceCertificateEnvelopeResponse;
}>({
  url: 'structured-loans/create-compliance-certificate',
  method: 'post',
});
