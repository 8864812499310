import { CompanyTypeType } from '../common/CompanyTypeType';

export interface CompanyInterface {
  id: string;
  name: string;
  type: CompanyTypeType;
}

export const companyInterfacesToOptions = (companies: CompanyInterface[]): { label: string; value: string }[] =>
  companies.map(({ id, name }) => ({ label: name, value: id }));
