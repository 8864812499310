import * as Yup from 'yup';
import { baseRates } from '../loan/BaseRateNameType';
import {
  SLCustomDynamicInterestRateType,
  SLCustomInterestRateType,
  SLFixedInterestRateType,
  SLFloatingInterestRateType,
} from '../structured-loan/InterestRateType';
import { NumberStripEmpty, ObjectStripUnknown } from './atoms';
import { literal, sumTypeOptional } from './base';
import { enumStripEmpty } from './options';

const FixedInterestRateValidation = ObjectStripUnknown<SLFixedInterestRateType>({
  type: literal('fixed'),
  rate: NumberStripEmpty,
});

const FloatingInterestRateValidation = ObjectStripUnknown<SLFloatingInterestRateType>({
  type: literal('floating'),
  'base-rate-name': enumStripEmpty(baseRates),
  'base-rate-floored': Yup.bool(),
  'base-rate-floor': NumberStripEmpty,
  'base-rate-ceiling': NumberStripEmpty,
  margin: NumberStripEmpty,
  'quotation-days': NumberStripEmpty.integer(),
});

const CustomInterestRateValidation = ObjectStripUnknown({
  type: literal('custom'),

  // The schedule is never passed along in the request and therefore is not
  // validated. Because of this bad design, a cast is necessary.
}) as unknown as Yup.ObjectSchema<SLCustomInterestRateType>;

const CustomDynamicInterestRateValidation = ObjectStripUnknown<SLCustomDynamicInterestRateType>({
  type: literal('custom-dynamic'),
});

export const SLInterestRateValidation = sumTypeOptional('type', {
  fixed: FixedInterestRateValidation,
  floating: FloatingInterestRateValidation,
  custom: CustomInterestRateValidation,
  'custom-dynamic': CustomDynamicInterestRateValidation,
});
