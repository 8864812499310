import { array, string } from 'yup';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { literal, sumTypeOptional } from '../validation/base';

export const restrictedOptions = ['listedOnly', 'listedAndMore', 'no'] as const;
export type RestrictedOption = (typeof restrictedOptions)[number];
export const prettyRestrictedOptions = {
  no: 'No',
  listedAndMore: 'Yes, listed accounts and free to add more',
  listedOnly: 'Yes, listed accounts only',
} satisfies Record<RestrictedOption, string>;

/**
 * Restrictions on bank accounts connected to the loan.
 *
 * `restricted` should not be undefined in running loans, and the list should
 * not be empty unless `restricted` is `'no'`.
 */
export type BankAccountRestrictions = {
  /**
   * It's important to our customers to be able to distinguish between 'considered and chose no'
   * and 'not considered'
   */
  restricted?: RestrictedOption;
  accounts?: RestrictedBankAccount[];
};

/**
 * This also specifies the ordering in the UI.
 */
export const restrictedBankAccountStatuses = [
  'blocked',
  'softPledged',
  'waterfall',
  'cashSweep',
  'notPledged',
] as const;
export type RestrictedBankAccountStatus = (typeof restrictedBankAccountStatuses)[number];
export const prettyRestrictedBankAccountStatus = {
  blocked: 'Blocked',
  cashSweep: 'Cash Sweep',
  notPledged: 'Not Pledged',
  softPledged: 'Soft Pledged',
  waterfall: 'Waterfall',
} satisfies Record<RestrictedBankAccountStatus, string>;

export type RestrictedBankAccountLegalEntityOption = { tag: 'fund' } | { tag: 'facility'; id?: string };

/**
 * Status should not be undefined in running loans.
 */
export type RestrictedBankAccount = {
  name: string;
  iban?: string;
  status?: RestrictedBankAccountStatus;
  legalEntity?: RestrictedBankAccountLegalEntityOption;
  description?: string;
};

export const restrictedBankAccountSchema = ObjectStripUnknown<RestrictedBankAccount>({
  name: TextStripEmpty.required(),
  iban: TextStripEmpty.optional(),
  legalEntity: sumTypeOptional('tag', {
    fund: ObjectStripUnknown({ tag: literal('fund').required() }).required(),
    facility: ObjectStripUnknown({
      tag: literal('facility').required(),
      id: TextStripEmpty.optional(),
    }).required(),
  }),
  description: TextStripEmpty.optional(),
  status: string().oneOf(restrictedBankAccountStatuses).optional(),
}).required();

export const bankAccountRestrictionsSchema = ObjectStripUnknown<BankAccountRestrictions>({
  restricted: string().oneOf(restrictedOptions).optional(),
  accounts: array<RestrictedBankAccount>(restrictedBankAccountSchema).optional(),
}).required();
