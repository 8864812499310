import Auth from '@aws-amplify/auth';
import { currencyOptions, RsCognitoUser, RsDateFieldValidator } from '@realstocks/types';
import { setYear } from 'date-fns';
import { Formik, FormikHelpers } from 'formik';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, NavigateFunction } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import { AGE_SELECT_MAX_YEAR, AGE_SELECT_MIN_YEAR } from '../../../constants/Generic';
import { setLoading } from '../../../redux/redux-app/app-actions';
import { getCurrentUserOrNull } from '../../../redux/redux-auth/auth-selectors';
import ClientPath from '../../../routes/ClientPath';
import { getAndPopulateCurrentUser } from '../../../services/api/get-and-populate-current-user';
import { formatPhone, getCountryCode, getPhoneNr } from '../../../services/utils/get-full-phone-format';
import PhoneCodes from '../../../static-data/phone-codes.json';
import { AppState } from '../../../store';
import { withNavigation } from '../../navigation-hoc/withNavigation';
import RsCheckbox from '../../rs-form/rs-checkbox/RsCheckbox';
import RsDatePicker from '../../rs-form/rs-datepicker/RsDatePicker';
import RsInput from '../../rs-form/rs-input/RsInput';
import RsSelect from '../../rs-form/rs-select/RsSelect';
import RsForm from '../../rs-form/RsForm';
import { createErrorStatus } from '../../rs-form/utils/create-error-status';
import './UserForm.scss';

type UserFormType = {
  firstName: string;
  surname: string;
  email: string;
  emailRep: string;
  /** Phone country code */
  countrycode: string;
  phone: string;
  currency: string;
  position: string;
  birthdate: string | null;
  password: string;
  passwordRep: string;
  tos: boolean;
};
type Props = {
  setLoading: Function;
  currentUser: RsCognitoUser | null;
  children?: React.ReactNode;
  successCallback?: Function;
  navigate: NavigateFunction;
};
type State = {
  user: UserFormType;
  formValidationSchema: Yup.Lazy;
};

class UserForm extends Component<Props, State> {
  componentDidMount() {
    this.setCurrentUser();
  }

  componentDidUpdate(prevProps: Props, _prevState: State) {
    if (prevProps.currentUser !== this.props.currentUser) {
      this.setCurrentUser();
    }
  }

  state: State = {
    user: {
      firstName: '',
      surname: '',
      email: '',
      countrycode: '',
      phone: '',
      currency: '',
      position: '',
      password: '',
      passwordRep: '',
      emailRep: '',
      birthdate: null,
      tos: false,
    },
    formValidationSchema: Yup.lazy<UserFormType>((_user: UserFormType) => {
      let userCreateSchema = {};
      const userFormSchema: any = {
        firstName: Yup.string().required('First Name is required.'),
        surname: Yup.string().required('Surname is required.'),
        email: Yup.string().email('Invalid Email Address.').required('Email is required.'),
        countrycode: Yup.string().required('Country Code is required.'),
        phone: Yup.string().required('Phone is required.'),
        currency: Yup.string().required('Currency is required.'),
        position: Yup.string().required('Position is required.'),
        password: Yup.string(),
        passwordRep: Yup.string(),
        emailRep: Yup.string(),
        birthdate: RsDateFieldValidator().required('Date of Birth is required.'),
        tos: Yup.bool(),
      };

      if (!this.props.currentUser) {
        userCreateSchema = {
          password: Yup.string()
            .required('Password is required.')
            .min(8, 'Password must be at least 8 characters long.'),
          emailRep: Yup.string()
            .test('match', 'Confirmation email should match the email.', function (confirmedEmail: string) {
              const { email } = this.parent;
              return confirmedEmail === email;
            })
            .required('Password confirmation is required.'),
          passwordRep: Yup.string()
            .test('match', 'Confirmation password should match the password.', function (confirmedPassword: string) {
              const { password } = this.parent;
              return confirmedPassword === password;
            })
            .required('Password confirmation is required.'),
          tos: Yup.bool().oneOf([true], 'Privacy Policy must be accepted.'),
        };
      }

      return Yup.object({ ...userFormSchema, ...userCreateSchema });
    }),
  };

  setCurrentUser = () => {
    if (this.props.currentUser) {
      this.setState({
        user: {
          firstName: this.props.currentUser.attributes['custom:firstName'],
          surname: this.props.currentUser.attributes['custom:surname'],
          email: this.props.currentUser.attributes.email,
          countrycode: getCountryCode(this.props.currentUser.attributes['custom:phone']),
          phone: getPhoneNr(this.props.currentUser.attributes['custom:phone']),
          currency: this.props.currentUser.attributes['custom:currency'],
          position: this.props.currentUser.attributes['custom:position'],
          password: '',
          passwordRep: '',
          emailRep: this.props.currentUser.attributes.email,
          birthdate: this.props.currentUser.attributes.birthdate,
          tos: false,
        },
      });
    }
  };

  onSubmit = async (values: UserFormType, actions: FormikHelpers<UserFormType>) => {
    try {
      const { currentUser } = this.props;
      let profileUpdated = false;

      const attributes: any = {
        'custom:firstName': currentUser ? currentUser.attributes['custom:firstName'] : values.firstName,
        'custom:surname': currentUser ? currentUser.attributes['custom:surname'] : values.surname,
        'custom:phone': formatPhone(values.countrycode, values.phone),
        'custom:position': values.position,
        'custom:currency': values.currency,
        'custom:userId': (currentUser && currentUser.attributes['custom:userId']) || uuid(),
        birthdate: values.birthdate || '',
      };

      if (this.props.currentUser) {
        this.props.setLoading('Saving your profile information...');

        await Auth.updateUserAttributes(this.props.currentUser, attributes);
        await getAndPopulateCurrentUser();
        profileUpdated = true;
      } else {
        this.props.setLoading('Creating your account...');

        await Auth.signUp({
          username: values.email,
          password: values.password,
          attributes,
        });

        this.props.navigate(
          {
            pathname: ClientPath.auth.verifyAccount,
            search: `?email=${encodeURIComponent(values.email)}`,
          },
          { state: { p: values.password } }
        );
      }

      if (this.props.successCallback) this.props.successCallback(profileUpdated);
    } catch (error) {
      actions.setStatus(createErrorStatus(error));
      this.props.setLoading(false);
    } finally {
      this.props.setLoading(false);
    }
  };

  render() {
    return (
      <div className="user-form">
        <Formik
          enableReinitialize={true}
          validationSchema={this.state.formValidationSchema}
          initialValues={this.state.user}
          onSubmit={this.onSubmit}
        >
          {({ status, setFieldValue }) => (
            <RsForm
              status={status}
              submitLabel={this.props.currentUser ? 'Update' : 'Register'}
              id="signup-form"
              className="user-signup-form"
            >
              <div className="columns user-form-container is-centered is-multiline">
                {this.props.children || ''}
                <div className="column is-12 is-narrow">
                  <RsInput
                    disabled={this.props.currentUser !== null}
                    icon="far fa-user"
                    type="text"
                    label="First Name"
                    name="firstName"
                  />
                </div>
                <div className="column is-12 is-narrow">
                  <RsInput
                    disabled={this.props.currentUser !== null}
                    icon="far fa-user"
                    type="text"
                    label="Last Name"
                    name="surname"
                  />
                </div>

                <div className="column is-12 is-narrow">
                  <RsInput
                    disabled={Boolean(this.props.currentUser)}
                    icon="far fa-envelope"
                    type="email"
                    label="Email"
                    name="email"
                  />
                </div>

                <div className="column is-12 is-narrow">
                  <RsInput
                    disabled={Boolean(this.props.currentUser)}
                    icon="far fa-envelope"
                    type="email"
                    label="Email Confirmation"
                    name="emailRep"
                  />
                </div>

                <div className="column is-12 is-narrow">
                  <RsDatePicker
                    minDate={setYear(new Date(), AGE_SELECT_MIN_YEAR)}
                    maxDate={setYear(new Date(), AGE_SELECT_MAX_YEAR)}
                    type="text"
                    label="Date of Birth"
                    name={`birthdate`}
                    setFieldValue={setFieldValue}
                  />
                </div>

                <div className="column is-12 is-narrow">
                  <RsSelect
                    data={PhoneCodes.phoneCodes}
                    datalabelkey="name"
                    datavaluekey="dial_code"
                    label="Prefix"
                    name="countrycode"
                    defaultOptionLabel="Country (Prefix)"
                  />
                </div>

                <div className="column is-12 is-narrow">
                  <RsInput type="text" label="Phone Number" icon="fas fa-mobile-alt" name="phone" />
                </div>

                <div className="column is-12 is-narrow">
                  <RsSelect
                    data={currencyOptions}
                    datalabelkey="label"
                    datavaluekey="value"
                    label="Default Account Currency"
                    name="currency"
                  />
                </div>

                <div className="column is-12 is-narrow">
                  <RsInput type="text" label="Position" icon="fas fa-sitemap" name="position" />
                </div>

                {this.props.currentUser === null && (
                  <Fragment>
                    <div className="column is-12 is-narrow">
                      <RsInput type="password" label="Password" icon="fas fa-lock" name="password">
                        Your password needs to:
                        <br />
                        <ul>
                          <li>be at least 8 characters long</li>
                          <li>include both minimum one number and one special character</li>
                          <li>include both minimum one uppercase and one lowercase letter</li>
                        </ul>
                      </RsInput>
                    </div>

                    <div className="column is-12 is-narrow">
                      <RsInput type="password" label="Confirm Password" icon="fas fa-lock" name="passwordRep" />
                    </div>
                  </Fragment>
                )}
              </div>
              {this.props.currentUser === null && (
                <div className="column is-full no-padding">
                  <RsCheckbox name="tos" hideEmptyLabelContainer align="center">
                    <p>
                      I accept the
                      <Link
                        target="_blank"
                        to={{
                          pathname: ClientPath.static.privacyPolicy,
                          search: 'newTab=true',
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </p>
                  </RsCheckbox>
                </div>
              )}
            </RsForm>
          )}
        </Formik>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoading: setLoading,
};

const mapStateToProps = (state: AppState) => {
  return { currentUser: getCurrentUserOrNull(state) };
};

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(UserForm));
