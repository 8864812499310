import * as Yup from 'yup';
import { ObjectStripUnknown, TextStripEmpty } from './atoms';
import { literal, sumTypeRequired } from './base';
import { EventViewRequestValidation } from './EventViewRequestValidation';

export const CustomDynamicScheduleViewRequestValidation = sumTypeRequired('type', {
  'next-command-or-event': ObjectStripUnknown({
    type: literal('next-command-or-event'),
    loanId: TextStripEmpty.required(),
    sequenceId: TextStripEmpty.required(),
    periodIndex: Yup.number().required(),
  }),
  event: EventViewRequestValidation,
});
