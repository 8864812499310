import { isValid } from 'date-fns';
import { isArray, isEmpty, isObject } from 'lodash';
import * as Yup from 'yup';

export const Text = Yup.string().trim();

// Array of zero or more items matching a given schema.
// Yup's defaults makes this an error prone thing to express.
// https://github.com/jquense/yup/issues/189
export const RequiredArray = <T>(item: Yup.Schema<T>) => Yup.array(item).test('isArray', 'Array is required', isArray);

// Text is trimmed and empty strings are converted to undefined.
export const TextStripEmpty = Yup.string()
  .trim()
  .transform(x => (isEmpty(x) ? undefined : x));

// The front end number inputs in certain cases return '' values. These values
// are cast to NaN's by Yup and are silently stripped out by this utility.
export const NumberStripEmpty = Yup.number().transform(x => (isNaN(x) ? undefined : x));

export const AmountNumber = (label?: string) =>
  label ? Yup.number().positive().min(0).label(label) : Yup.number().positive().min(0);

// Unknown fields are silently stripped away.
// The use of default(undefined) preserves undefined values as undefined.
// Otherwise Yup transforms undefined to an object.
export const ObjectStripUnknown = <T extends object>(body: Yup.ObjectSchemaDefinition<T>): Yup.ObjectSchema<T> =>
  Yup.object(body).noUnknown(true).default(undefined);

export const OptionalDate = (msg = 'Invalid Date') =>
  Yup.mixed().test('Date', msg, (value: any) => {
    return value === undefined || (typeof value === 'string' && isValid(new Date(value)));
  });

export const RequiredDate = (msg = 'Invalid Date') =>
  Yup.mixed<string>().test('Required date', msg, (value: any) => {
    return typeof value === 'string' && isValid(new Date(value));
  });

// A required key-value object with values matching the given value schema.
export const RequiredMapOf = (valueSchema: Yup.Schema<any>) =>
  Yup.lazy((obj: any) => {
    if (isObject(obj) && !isArray(obj)) {
      return ObjectStripUnknown(Object.fromEntries(Object.keys(obj).map(key => [key, valueSchema])));
    } else {
      return Yup.object().required();
    }
  });
