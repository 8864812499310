import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { DocumentAssemblyType } from '../document-assembly/DocumentAssemblyType';
import { DocuSignEnvelopeStatus } from '../structured-loan/docusign';
import { StructuredLoanState } from '../structured-loan/structured-loan-state';
import { ObjectStripUnknown, RequiredDate } from '../validation/atoms';
import { literal, sumTypeRequired } from '../validation/base';
import { EventViewRequestValidation } from '../validation/EventViewRequestValidation';
import { EventViewRequest } from './EventViewRequest';
import {
  LCECovenant,
  LCECovenantCollection,
  LCECovenantDocument,
  LCECovenantInterval,
  LCEMetric,
} from './lce-covenants';
import { CovenantCollectionDraft } from './save-covenant-collection-api';

export type CovenantCollectionViewRequest =
  | {
      type: 'next-command-or-event';
      loanId: string;
      covenantId: string;
      checkDate: string;
    }
  | EventViewRequest;

export const covenantCollectionViewRequestValidation = sumTypeRequired('type', {
  'next-command-or-event': ObjectStripUnknown({
    type: literal('next-command-or-event'),
    loanId: Yup.string().required(),
    covenantId: Yup.string().required(),
    checkDate: RequiredDate(),
  }),
  event: EventViewRequestValidation,
});

export type CovenantCollectionView = NextCovenantCollectionView | PastCovenantCollectionView;

export type NextCovenantCollectionView = CommonCovenantCollectionView & {
  type: 'next';

  /** The covenant collection draft, if a draft has been saved. Otherwise the
      draft is undefined. */
  draft?: CovenantCollectionDraft;
};

export type PastCovenantCollectionView = CommonCovenantCollectionView & {
  type: 'past';
  eventId: string;
  documents: LCECovenantDocument[];
};

export type CommonCovenantCollectionView = {
  loanId: string;
  version: number;
  state: StructuredLoanState;

  /** True if the envelopes section should be shown. */
  showEnvelopes: boolean;

  /** True if the user is allowed to create the DocuSign envelope. */
  canCreateDocuSignEnvelope: boolean;

  /** True if the user is allowed to generate outbound covenant notifications. */
  canCreateOutboundNotifications: boolean;

  covenant: LCECovenant;
  interval: LCECovenantInterval;
  metrics: { [metricId: string]: LCEMetric };
  facilities: { [facilityId: string]: { name: string; legalEntityName?: string } };
  sequences: { [sequenceId: string]: { name: string } };
  properties: { [propertyId: string]: { name?: string } };
  collections: LCECovenantCollection[];
  envelopes: CovenantCollectionEnvelope[];
  notificationLenders: { id: string; name: string }[];
  responsibleUsers: CovenantCollectionResponsibleUser[];

  /** The url of the document assembly is the empty string, if the compliance
      certificate template has not been configured. */
  documentAssembly: DocumentAssemblyType;

  /** The name of the covenant template or undefined, if no template has been
      configured. */
  templateName: string | undefined;

  /** URLs for the draft documents ('next' case) or the LCE documents ('past' case). */
  downloadUrls: { [fileKey: string]: { url: string } };
};

export type CovenantCollectionEnvelope = {
  loanId: string;
  envelopeId: string;
  createdAt?: string;
  signers: {
    name: string;
    email: string;
  }[];
  status: DocuSignEnvelopeStatus;
  templateType?: 'covenant-default' | 'one-off';
  updatedAt?: string;
};

export type CovenantCollectionResponsibleUser = {
  userId: string;
  name: string;
  email: string;
};

export const covenantCollectionViewApi = defineApi<{
  body: CovenantCollectionViewRequest;
  response: CovenantCollectionView;
}>({
  url: 'structured-loans/covenant-collection-view',
  method: 'post',
});
