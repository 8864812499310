import { InferType, object, string } from 'yup';
import { defineApi } from '../common/api-definition';
import { CommonLoanView } from './common-loan';
import { StructuredAgreementType } from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';

export type AgreementOverviewView = {
  version: number;
  state: StructuredLoanState;
  loan: StructuredAgreementType;

  agentName?: string;
  borrowerName?: string;
  lenderNames: string[];

  // The total commitment is undefined, if no commitments have been configured.
  totalCommitment?: { amount: number; currency: string };

  // The total commitments for each facility.
  facilityTotalCommitments: {
    [facilityId: string]:
      | {
          amount: number;
          currency: string;
        }
      | undefined;
  };

  // The names of the responsible users
  responsibleUserNames?: string[];

  commonView: CommonLoanView;
};

export const AgreementOverviewViewRequestValidation = object({
  loanId: string().required(),
});

export type AgreementOverviewViewRequest = InferType<typeof AgreementOverviewViewRequestValidation>;

export const agreementOverviewViewApi = defineApi<{
  body: AgreementOverviewViewRequest;
  response: AgreementOverviewView;
}>({
  url: 'structured-loans/agreement-overview-view',
  method: 'post',
});
