export const AllBaseRates = {
  EURIBOR: 'EURIBOR',
  STIBOR: 'STIBOR',
  NIBOR: 'NIBOR',
  LIBOR: 'LIBOR',
  CIBOR: 'CIBOR',
  WIBOR: 'WIBOR',
} as const;
export type BaseRateNameType = keyof typeof AllBaseRates;

export const baseRates = Object.keys(AllBaseRates) as BaseRateNameType[];

export const prettyBaseRateName = (baseRateName: BaseRateNameType) => AllBaseRates[baseRateName];
