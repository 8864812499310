import * as Yup from 'yup';
import { AccessRightScopeType } from '../access-rights/action-definitions';
import { defineApi } from '../common/api-definition';
import { CompanyUserRoleType } from '../company/CompanyUserType';
import { LceNarrow } from '../utils/lce-discriminated-unions';
import { ObjectStripUnknown, RequiredArray, RequiredMapOf, TextStripEmpty } from '../validation/atoms';
import { literal, sumTypeRequired } from '../validation/base';
export type InviteUserRoles = Record<string, CompanyUserRoleType>;

export type InviteUserRequest = {
  firstName: string;
  lastName: string;
  email: string;
  birthdate: string;
  prefix: string;
  phoneNumber: string;
  position: string;
  currency: string;
  roles: InviteUserRoles;
};

export type InviteUserResponse = { message: string };

export const accessRightScopeValidation = sumTypeRequired('type', {
  all: ObjectStripUnknown<LceNarrow<AccessRightScopeType, 'all'>>({ type: literal('all') }),

  objects: ObjectStripUnknown<LceNarrow<AccessRightScopeType, 'objects'>>({
    type: literal('objects'),
    ids: RequiredArray(TextStripEmpty).required(),
  }),
  labels: ObjectStripUnknown<LceNarrow<AccessRightScopeType, 'labels'>>({
    type: literal('labels'),
    labels: RequiredArray(TextStripEmpty).required(),
  }),
});

export const companyUserRoleValidation = ObjectStripUnknown<CompanyUserRoleType>({
  roleId: Yup.string().required(),
  scope: accessRightScopeValidation,
});

export const inviteUserValidation = ObjectStripUnknown<InviteUserRequest>({
  firstName: TextStripEmpty.required(),
  lastName: TextStripEmpty.required(),
  email: TextStripEmpty.required(),
  birthdate: TextStripEmpty.required(),
  prefix: TextStripEmpty.required(),
  phoneNumber: TextStripEmpty.required(),
  position: TextStripEmpty.required(),
  currency: TextStripEmpty.required(),
  roles: RequiredMapOf(companyUserRoleValidation.required()),
}).required();

export const inviteUserApi = defineApi<{
  response: InviteUserResponse;
  body: InviteUserRequest;
}>({
  method: 'post',
  url: 'users/invite-user',
});
