import * as Yup from 'yup';
import { RsLinkType } from '../common/RsLinkType';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { securitiesAndGuaranteesSchema } from '../structured-loan/SecuritiesAndGuarantees';
import { UpdateLoanDocumentsAndSecurities } from '../structured-loan/SLEventType';
import { SLDocumentCategoryType } from '../structured-loan/structured-agreement-type';
import { ObjectStripUnknown, TextStripEmpty } from './atoms';
import { RsFileValidation } from './base';
import { RsLinkValidation } from './RsLinkValidation';

export const LoanDocumentsValidation = Yup.array<SLDocumentCategoryType>().of(
  Yup.object<SLDocumentCategoryType>({
    id: Yup.string(),
    name: Yup.string().required('The category name is required'),
    description: Yup.string().optional(),
    links: Yup.array<RsLinkType>().of<RsLinkType>(RsLinkValidation),
    documents: Yup.array<RsUploadedFileType>().of<RsUploadedFileType>(RsFileValidation),
  })
);

export const SLUpdateDocumentsAndSecuritiesValidation = ObjectStripUnknown<UpdateLoanDocumentsAndSecurities>({
  type: Yup.string().oneOf(['loan-documents-and-securities-update']).required(),
  loanId: TextStripEmpty.required(),
  version: Yup.number().required(),
  documentCategories: LoanDocumentsValidation.required(),
  securitiesAndGuarantees: securitiesAndGuaranteesSchema.optional(),
});
