import { isArray } from 'lodash';
import { array, object, string } from 'yup';
import { defineApi } from '../common/api-definition';
import { StructuredLoanNextCommand } from './NextCommandType';

export type NextCommandsView = {
  items: NextCommandItemView[];
};

export type NextCommandItemView = {
  loanId: string;
  loanName: string;

  // Facility and sequence names are set only for sequence commands.
  facilityName?: string;
  sequenceName?: string;

  description: string;
  nextCommand: StructuredLoanNextCommand;
};

export const nextCommandsViewRequestValidation = object({
  loanIds: array(string()).test('isArray', 'Array is required', isArray),
});

export type NextCommandsViewRequest = {
  loanIds: string[];
};

export const nextCommandsViewApi = defineApi<{
  body: NextCommandsViewRequest;
  response: NextCommandsView;
}>({
  url: 'structured-loans/next-commands-view',
  method: 'post',
});
