import * as Yup from 'yup';
import { RsUploadedFileMetadataType, RsUploadedFileType } from '../common/RsUploadedFileType';
import { ObjectStripUnknown, TextStripEmpty } from './atoms';

export const RsUploadedFileMetadataValidation = ObjectStripUnknown<RsUploadedFileMetadataType>({
  acl: TextStripEmpty.required(),
  isCover: Yup.bool(),
});

export const RsUploadedFileValidation = ObjectStripUnknown<RsUploadedFileType>({
  id: TextStripEmpty.required(),
  fieldName: TextStripEmpty.required(),
  name: TextStripEmpty.required(),
  type: TextStripEmpty.required(),
  bucket: TextStripEmpty.required(),
  key: TextStripEmpty,
  status: Yup.string().oneOf(['uploading', 'error', 'success', 'ready']).required(),
  metadata: RsUploadedFileMetadataValidation,
  lastModified: Yup.number(),
});
