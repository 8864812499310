import * as Yup from 'yup';
import { defineApi } from '../common/api-definition';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { CompanyInterface } from '../company/company.interface';
import { ObjectStripUnknown } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';
import { RsUploadedFileValidation } from '../validation/UploadedFileValidation';
import { TemplatePurpose } from './template-type.enum';

export type TemplatesTableRowData = {
  file: RsUploadedFileType;
  downloadUrl: string;
  templateId: string;
  templateName: string;
  companyId: string;
  companyName?: string;
};

type TemplatesViewRequest = { templateType: TemplatePurpose };

export type TemplatesView = TemplatesViewAdmin | TemplatesViewCompany;

export type TemplatesViewCommon = {
  templateType: TemplatePurpose;
  templates: TemplatesTableRowData[];
};

type TemplatesViewAdmin = TemplatesViewCommon & {
  type: 'admin';
  ownerCompanies: CompanyInterface[];
};

type TemplatesViewCompany = TemplatesViewCommon & {
  type: 'company';
  userCompanyId: string;
};

const templatePurposeSchema = Yup.string().oneOf<TemplatePurpose>([
  TemplatePurpose.covenant_certificate_templates,
  TemplatePurpose.payment_notice_templates,
  TemplatePurpose.rate_fixing_notice_templates,
  TemplatePurpose.facility_agreement_templates,
  TemplatePurpose.nda_templates,
]);

export type CreateTemplateRequest = {
  templateFile: RsUploadedFileType;
  templateType: TemplatePurpose;
  templateName: string;
  companyId: string;
};

export type UpdateTemplateRequest = {
  templateId: string;
  templateFile: RsUploadedFileType;
  templateName: string;
};

export const templateApi = {
  createTemplate: defineApi<{ body: CreateTemplateRequest }>({
    url: 'templates/create-template',
    method: 'post',
  }),
  createTemplateSchema: ObjectStripUnknown<CreateTemplateRequest>({
    templateFile: RsUploadedFileValidation.required(),
    templateType: templatePurposeSchema.required(),
    templateName: NonEmptyString.required(),
    companyId: NonEmptyString.required(),
  }),

  updateTemplate: defineApi<{ body: UpdateTemplateRequest }>({
    url: 'templates/update-template',
    method: 'post',
  }),
  updateTemplateSchema: ObjectStripUnknown<UpdateTemplateRequest>({
    templateId: NonEmptyString.required(),
    templateFile: RsUploadedFileValidation.required(),
    templateName: NonEmptyString.required(),
  }),

  getTemplatesView: defineApi<{ body: TemplatesViewRequest; response: TemplatesView }>({
    url: 'templates/templates-view',
    method: 'post',
  }),
  templatesViewSchema: ObjectStripUnknown<TemplatesViewRequest>({
    templateType: templatePurposeSchema.required(),
  }).required(),
};
