import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown, TextStripEmpty } from '../validation/atoms';
import { CommonLoanView } from './common-loan';
import { StructuredAgreementType } from './structured-agreement-type';

export type StartExternalIDView = {
  loan: StructuredAgreementType;
  commonView: CommonLoanView;
};

export type StartExternalIDRequest = {
  loanId: string;
};

export const startExternalIDValidation = ObjectStripUnknown<StartExternalIDRequest>({
  loanId: TextStripEmpty.required(),
}).required();

export const startExternalIDApi = defineApi<{
  body: StartExternalIDRequest;
  response: StartExternalIDView;
}>({
  url: 'structured-loans/start-external-ID',
  method: 'post',
});
