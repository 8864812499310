export function flattenObject(obj: Record<string, any>, prefix = '') {
  return Object.keys(obj).reduce<Record<string, string>>((acc, key) => {
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (typeof obj[key] === 'string') {
      acc[newKey] = obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], newKey));
    }

    return acc;
  }, {});
}

export const isEmptyObject = (obj: Record<string, unknown>) => Object.keys(obj).length === 0;
