import { extend } from 'lodash';
import { Component } from 'react';
import Lottie, { Options } from 'react-lottie';
import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import * as loadingAnimation from '../../assets/lottie/loading.json';
import { getLoading, LoadingStateType } from '../../redux/redux-app/app-selectors';
import { AppState } from '../../store';

const loadingLottieAnimation: any = loadingAnimation;

type Props = {
  loading: LoadingStateType;
  animation?: any; // json file
  options?: Options | any;
  lottieProps?: any;
  message?: LoadingStateType;
  width?: number;
  height?: number;
  className?: string;
};

type State = {
  isStopped: any;
  isPaused: any;
};

class LottieComponent extends Component<Props, State> {
  state: State = { isStopped: false, isPaused: false };

  render() {
    const { loading, message } = this.props;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: this.props.animation ? this.props.animation.default : loadingLottieAnimation.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <div className={`lottie-container ${this.props.className || ''}`}>
        <Lottie
          isClickToPauseDisabled
          options={extend(defaultOptions, this.props.options)}
          height={this.props.height || 250}
          width={this.props.width || 250}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
          {...this.props.lottieProps}
        />

        {loading ? (
          <h3 className="has-text-centered lottie-message">{loading}</h3>
        ) : message ? (
          <h3 className="has-text-centered lottie-message">{message}</h3>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    loading: getLoading(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LottieComponent);
