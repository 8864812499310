import { isBoolean } from 'lodash';
import * as Yup from 'yup';
import {
  NumberStripEmpty,
  ObjectStripUnknown,
  OptionalDate,
  RequiredArray,
  RequiredDate,
  RequiredMapOf,
  TextStripEmpty,
} from './atoms';
import { literal, sumTypeRequired } from './base';
import { RsUploadedFileValidation } from './UploadedFileValidation';

const SLInformationCollectionDraftValidation = ObjectStripUnknown({
  entityId: TextStripEmpty.required(),
  metricId: TextStripEmpty.required(),
  value: Yup.lazy(value => (isBoolean(value) ? Yup.boolean() : NumberStripEmpty)),
  reportingDate: OptionalDate(),
  reportedBy: TextStripEmpty,
});

export const SLInformationCollectionValidation = ObjectStripUnknown({
  entityId: TextStripEmpty.required(),
  metricId: TextStripEmpty.required(),
  value: Yup.lazy(value => (isBoolean(value) ? Yup.boolean().required() : NumberStripEmpty.required())),
});
const SLInformationDocumentsValidation = RequiredMapOf(RequiredMapOf(RequiredArray(RsUploadedFileValidation)));

export const UpdateInformationUndertakingSLEventValidation = ObjectStripUnknown({
  type: literal('update'),
  collections: RequiredMapOf(RequiredMapOf(SLInformationCollectionDraftValidation)),
  documents: SLInformationDocumentsValidation,
});

export const SubmitInformationUndertakingSLEventValidation = ObjectStripUnknown({
  type: literal('submit'),
  collections: RequiredMapOf(RequiredMapOf(SLInformationCollectionValidation)),
  documents: SLInformationDocumentsValidation,
});

export const SkipInformationUndertakingSLEventValidation = ObjectStripUnknown({
  type: literal('skip'),
});

export const InformationUndertakingSLEventValidation = sumTypeRequired('type', {
  update: UpdateInformationUndertakingSLEventValidation,
  submit: SubmitInformationUndertakingSLEventValidation,
  skip: SkipInformationUndertakingSLEventValidation,
});

export const ModifyInformationUndertakingRequestValidation = ObjectStripUnknown({
  params: ObjectStripUnknown({
    version: Yup.number().min(0).required(),
    loanId: TextStripEmpty.required(),
    informationUndertakingId: TextStripEmpty.required(),
    checkDate: RequiredDate(),
  }),
  event: InformationUndertakingSLEventValidation,
});
