import { keys } from '../utils/type-utils';

export type DayCountConventionType = 'actual-360' | 'actual-365' | 'actual-actual' | '30-360' | '30-365';

export const AllDayCountConventionLabels: Record<DayCountConventionType, string> = {
  'actual-360': 'Actual/360',
  'actual-365': 'Actual/365',
  'actual-actual': 'Actual/Actual',
  '30-360': '30/360',
  '30-365': '30/365',
};

export const dayCountConventions = keys(AllDayCountConventionLabels);
