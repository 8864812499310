import { AccessRightsActionsMap } from '../access-rights/AccessRightsMap';
import { CompanyTypeType } from '../common/CompanyTypeType';
import { Pagination } from '../common/pagination.type';
import { Sorting } from '../common/sorting.type';
import { CompanyLabel } from '../company/CompanyLabel';
import { MultiLoanFilter } from '../filters/loan-filters.dto';
import { StructuredAgreementType, StructuredCapTableEntry } from './structured-agreement-type';
import { StructuredLoanState } from './structured-loan-state';
import { StructuredLoanStateType } from './structured-loan-state-type';
import { StructuredStartConfigurationType } from './structured-start-configuration-type';

export enum LoansQuerySortingKey {
  Id = 'id',
  Name = 'name',
  CreatedAt = 'createdAt',
  Borrower = 'borrower',
  Lender = 'lender',
  Label = 'label',
  Currency = 'currency',
  OutstandingDebt = 'outstandingDebt',
  NumberOfNextCommands = 'numberOfNextCommands',
}

export interface StructuredLoansQueryInputDto {
  statuses: (StructuredLoanState | 'TERMINATED')[];
  filters?: MultiLoanFilter;
  search?: string;
  sorting?: Sorting<LoansQuerySortingKey>;
  pagination?: Pagination;
}

export interface StructuredLoanParty {
  id: string;
  name: string;
  type: CompanyTypeType;
}

export interface StructuredLoansQueryOutputItem {
  id: string;
  name: string;
  labels: CompanyLabel[];
  borrowerId?: string;
  borrowerName?: string;
  lenders: StructuredLoanParty[];
  createdAt?: string;
  status: StructuredLoanState;
  currency?: string;
  outstandingDebtLCY?: number;
  numberOfNextCommands?: number;
}

export interface StructuredLoansQueryOutputDto {
  items: StructuredLoansQueryOutputItem[];
  count: number;
}

export type StructuredLoanRepresentation = {
  id: string;
  version: number;
  createdAt: string;
  labels: CompanyLabel[];
  accessRights: AccessRightsActionsMap;
  labelsVersion: number;
  source: string;
  structuredLoan: StructuredAgreementType;
  startConfiguration: StructuredStartConfigurationType;
  name: string;
  state: StructuredLoanState;
  additionalData?: Partial<AdditionalDataType>;
};

export type AdditionalDataType = {
  borrower: string;
  lenders: StructuredCapTableEntry[];
  currency: string;
  totalOutstandingDebt: number;
  loanLevelUpcomingEvents: number;
  loanLevelOverdueEvents: number;
  sequenceCurrentStatuses: SequenceCurrentStatus[];
};

export type SequenceCurrentStatus = {
  facilityId: string;
  facilityName: string;
  sequenceId: string;
  sequenceName: string;
  period: number;
  startDate: string;
  endDate: string;
  outstandingDebt: {
    amount: number;
    currency: string;
  };
  upcomingEvents?: number;
  overdueEvents?: number;
};

export type StructuredLoanInconsistentDTO = {
  hasIssues: true;
  id: string;
  name: string;
  createdAt?: string;
  errors: string[];
};

export type CombinedSLDataType = {
  dynamo: StructuredLoanRepresentation | undefined;
  lce?: StructuredLoanStateType | undefined;
  error?: string | undefined;
};

export type CombinedSLStatesDTO = {
  running: StructuredLoanRepresentation[];
  amend: StructuredLoanRepresentation[];
  aborted: StructuredLoanRepresentation[];
  drafts: StructuredLoanRepresentation[];
  issues?: StructuredLoanInconsistentDTO[];
};
