import { subDays } from 'date-fns';
import { RsUploadedFileType } from '../common/RsUploadedFileType';
import { prettyPeriod } from '../utils/data-store';
import { parseIsoDate } from '../utils/date-utils';
import { matchStringExhaustive } from '../utils/strings';
import { InterestPeriodType } from './InterestPeriodType';
import { REMetricCollectionChoice } from './RECollectionChoice';

export type LCEMetric = LCEPointMetric | LCEIntervalMetric | LCEFormulaMetric;

export type LCERawMetric = LCEPointMetric | LCEIntervalMetric;

export type LCEPointMetric = LCECommonRawMetric & {
  /** Values are collected for a point in time. */
  type: 'point';
  unit: LCEMetricUnit;
};

export type LCEIntervalMetric = LCECommonRawMetric & {
  /** Values are collected for an interval (span) of time. */
  type: 'interval';

  /** Booleans cannot be extrapolated, so it doesn't make sense to allow them as
      a unit of interval metrics. */
  unit: LCENonBooleanMetricUnit;
};

type LCECommonRawMetric = {
  id: string;
  name: string;
  description?: string;
  definition?: string;
  'metric-class'?: string;

  /** The currency must be set if and only if the unit of the metric is 'amount'. */
  currency?: string;

  'cc-binding'?: string;
  'proof-type'?: LCEMetricProofType;
  consequences?: LCEConsequence[];
  'reported-against': LCEReportedAgainst;
  /** If set then the metric must be collected either audited or unaudited.
      Otherwise the metric is never collected with a qualification, regardless
      of how the covenant is configured. */
  qualifications?: 'audited-unaudited';
};

export type LCEFormulaMetric = {
  type: 'formula';
  id: string;
  name: string;
  description?: string;
  definition?: string;
  'metric-class'?: string;

  /** Derived metrics always combine numbers and therefore cannot have a boolean
      as its unit. */
  unit: LCENonBooleanMetricUnit;

  /** The currency must be set if and only if the unit of the metric is 'amount'. */
  currency?: string;

  'cc-binding'?: string;
  'proof-type'?: LCEMetricProofType;
  consequences?: LCEConsequence[];

  lhs: LCEMetricSource;
  operator: LCEFormulaOperator;
  rhs: LCEMetricSource;
};

export type LCEConsequence = {
  type: 'hard-breach' | 'soft-breach';
  expression: LCEConsequenceExpression;
};

export const prettyLCEConsequenceType = (type: LCEConsequence['type']) =>
  matchStringExhaustive(type, {
    'soft-breach': () => 'Soft Breach',
    'hard-breach': () => 'Hard Breach',
  });

export type LCEConsequenceExpression =
  | {
      type: 'boolean';
      operator: 'eq';
      operand: LCEBooleanMetricValue;
    }
  | {
      type: 'number';
      operator: LCEConsequenceOperator;
      operand: LCENumberMetricValue;
    }
  | {
      type: 'ratio';
      operator: LCEConsequenceOperator;
      operand: LCERatioMetricValue;
    }
  | {
      type: 'amount';
      operator: LCEConsequenceOperator;
      operand: LCEAmountMetricValue;
    };

export type LCEConsequenceOperator = 'lt' | 'lte' | 'gt' | 'gte' | 'eq' | 'neq';

export type LCEMetricSource = LCEBuiltInSource | LCECustomSource;

export type LCEBuiltInSource = {
  type: 'built-in';
  id: LCEBuiltInMetricId;
};

export type LCECustomSource = {
  type: 'custom';
  id: string;
};

export type LCEFormulaOperator = 'div' | 'mul' | 'sub' | 'add';

export type LCEBuiltInMetricId = 'interest' | 'amortisation' | 'outstanding-debt';

export type LCEReportedAgainst = 'legal-entity' | 'property';

export type LCEMetricUnit = 'number' | 'boolean' | 'ratio' | 'amount';

export type LCENonBooleanMetricUnit = 'number' | 'ratio' | 'amount';

export type LCENumberMetricValue = {
  type: 'number';
  value: number;
};

export type LCERatioMetricValue = {
  type: 'ratio';
  value: number;
};

export type LCEAmountMetricValue = {
  type: 'amount';
  currency: string;
  value: number;
};

export type LCEBooleanMetricValue = {
  type: 'boolean';
  value: boolean;
};

export type LCESkipMetricValue = {
  type: 'skip';
};

export type LCENotApplicableMetricValue = {
  type: 'not-applicable';
};

export type LCEMissingMetricValue = {
  type: 'missing';
  unit: LCEMetricUnit;

  /** The currency is set if and only if unit is 'amount'. */
  currency?: string;
};

export type LCENumericMetricValue = LCENumberMetricValue | LCERatioMetricValue | LCEAmountMetricValue;

export type LCEMetricValue = LCENumericMetricValue | LCEBooleanMetricValue;

export type LCEMetricValueOrSkip = LCEMetricValue | LCESkipMetricValue;

export type LCEMetricValueOrSkipOrMissing = LCEMetricValue | LCESkipMetricValue | LCEMissingMetricValue;

export type LCECovenantMetricValue = LCEMetricValueOrSkipOrMissing | LCENotApplicableMetricValue;

export type LCEMetricCollection = {
  metric: LCERawMetric;
  interval: LCECovenantInterval;
  identifier: LCEMetricValueIdentifier;
  'metric-value': LCEMetricValueOrSkipOrMissing;
  proof?: LCEMetricProof;
  'past-identified-metric-values': LCEIdentifiedMetricValueOrMissing[];
};

export type LCEIdentifiedMetricValue = {
  identifier: LCEMetricValueIdentifier;
  'metric-value': LCEMetricValueOrSkip;
  proof?: LCEMetricProof;
};

export type LCEIdentifiedMetricValueOrMissing = {
  identifier: LCEMetricValueIdentifier;
  'metric-value': LCEMetricValueOrSkipOrMissing;
  proof?: LCEMetricProof;
};

export type LCEAnyIdentifiedMetricValue = {
  identifier: LCEMetricValueIdentifier;
  'metric-value': LCEMetricValueOrSkipOrMissing | LCENotApplicableMetricValue;
};

export type LCEMetricProof = {
  type: LCEMetricProofType;
  data: {
    proofDocuments: RsUploadedFileType[];
  };
};

export type LCEMetricProofType = 'financial-statement' | 'valuation-report' | 'certificate';

export type LCEMetricValueIdentifier = {
  metric: {
    type: LCERawMetric['type'];
    id: string;
    unit: LCEMetricUnit;

    /** The currency is set if and only if unit is 'amount'. */
    currency?: string;
  };
  qualification?: LCEQualification;
  interval: {
    period: InterestPeriodType;
    'start-date': string;
    'check-date': string;
  };
  /** The day of the month for the alignment of the check dates. Calendar
      aligned schedules have an alignment day of 1. The alignment day is
      necessary in order to distinguish past values for recurring schedules that
      happen to overlap for certain periods even though their alignments are
      different.
  */
  'alignment-day': number;
  entity: LCECovenantEntity;
};

export type LCECovenantEntity = LCEFundCovenantEntity | LCEFacilityCovenantEntity | LCEPropertyCovenantEntity;

export type LCEFundCovenantEntity = {
  type: 'fund';
};

export type LCEFacilityCovenantEntity = {
  type: 'facility';
  'facility-id': string;
};

export type LCEPropertyCovenantEntity = {
  type: 'property';
  'facility-id': string;
  'property-id': string;
};

export type LCECovenantInterval = {
  period: InterestPeriodType;
  'start-date': string;
  'end-date': string;
  'check-date': string;
  'due-date': string;
  deadline?: number;
};

export type LCEQualification = 'audited' | 'unaudited';

export type LCEExtrapolation = {
  type: 'extrapolation-interval';
  period: 'quarterly' | 'semi-yearly' | 'yearly';
};

export type LCECovenant = {
  id: string;
  name: string;
  'cc-template'?: string;
  schedule: LCEScheduleConfig;
  qualification?: LCEQualification;
  collections: LCECovenantCollectionConfig[];
  documents?: LCECovenantDocumentConfig[];
};

export type LCECovenantDocumentConfig = {
  type: 'required';
  id: string;
  name?: string;
};

export type LCECovenantDocument = LCERequiredCovenantDocument | LCEAdditionalCovenantDocument;

export type LCERequiredCovenantDocument = {
  type: 'required';
  'document-id': string;
  data: { files: RsUploadedFileType[] };
};

export type LCEAdditionalCovenantDocument = {
  type: 'additional';
  data: { files: RsUploadedFileType[] };
};

export type LCECovenantCollectionConfig = {
  source: LCECustomSource;
  period: InterestPeriodType;
  'collection-choice': REMetricCollectionChoice;
  extrapolation?: LCEExtrapolation;
};

export type LCEScheduleConfig = LCERecurringScheduleConfig | LCENonRecurringScheduleConfig;

export type LCERecurringScheduleConfig = {
  type: 'recurring';

  /** All test dates are greater than or equal to this date. If the start date
      is omitted, all test dates will be greater than the utilisation date of
      the sequence.

      If start date alignment is used, then the start date is required and the
      start date must be of type custom. */
  'start-date'?: LCEScheduleDate;

  /** All test dates must be before this date. If the termination date is
      omitted, all test dates will be before the termination date of the
      sequence. */
  'termination-date'?: LCEScheduleDate;

  period: InterestPeriodType;

  /** Whether periods are aligned with the months of the calendar or with a
      check date that aligns with the given first check date. */
  alignment: 'calendar' | 'start-date';

  deadline?: number;
};

export type LCENonRecurringScheduleConfig = {
  type: 'non-recurring';

  /** The single check date of the schedule. */
  'check-date': string;

  /** The duration of the single interval of the schedule. */
  period: InterestPeriodType;

  deadline?: number;
};

export type LCEScheduleDate =
  | {
      type: 'custom';
      date: string;
    }
  | {
      /** The date of termination of the first tranche of the sequence */
      type: 'first-tranche-termination';
    };

/** Interval for the collection of a metric value. */
export type LCEMetricInterval = {
  period: InterestPeriodType;
  'start-date': string;
  'end-date': string;
  'check-date': string;
};

export type LCECovenantCollection =
  | LCEFacilityCovenantCollection
  | LCEAggregatedFacilitiesCovenantCollection
  | LCEFundCovenantCollection;

export type LCEFacilityCovenantCollection = LCECommonCovenantCollection & {
  type: 'facility';
  'facility-id': string;
  details: {
    'facility-and-metric-values': LCEFacilityAndMetricValues[];
  };
};

export type LCEAggregatedFacilitiesCovenantCollection = LCECommonCovenantCollection & {
  type: 'aggregated-facilities';
  details: {
    'facility-and-metric-values': LCEFacilityAndMetricValues[];
  };
};

export type LCEFundCovenantCollection = LCECommonCovenantCollection & {
  type: 'fund';
  details: {
    'metric-id-values': {
      'metric-id': string;
      'metric-interval-values': LCEFundMetricIntervalValues;
    }[];
  };
};

export type LCECommonCovenantCollection = {
  /** The metric for the covenant value */
  metric: LCEMetric;

  /** The intervals from which metric values have been extracted. The values are
      extracted from multiple intervals only if extrapolation is configured.
  */
  intervals: LCEMetricInterval[];

  /** The covenant value. The value can be missing, skipped or not applicable,
      if for some reason or another the value cannot be computed from the
      collected metric values. */
  'covenant-value': LCECovenantMetricValue;

  'collection-config': LCECovenantCollectionConfig;

  /** The breaches for the covenant value. Each breach value corresponds to a
      consequence configuration in metric.consequences.
  */
  breaches: LCEBreachLevel[];

  /** Details about the calculation of the covenant value. */
  details: {
    'built-in-covenant-values': {
      'built-in-id': LCEBuiltInMetricId;
      'covenant-value': LCECovenantMetricValue;
    }[];
    'metric-covenant-values': {
      'metric-id': string;
      'covenant-value': LCECovenantMetricValue;
    }[];
    'built-in-values': LCEBuiltInValues[];
  };
};

export type LCEFacilityAndMetricValues = {
  'facility-id': string;
  'metric-id': string;
  'metric-interval-values': LCEFacilityAndMetricMetricIntervalValues;
};

export type LCEFacilityAndMetricMetricIntervalValues =
  | LCEFacilityAndMetricIdentifiedMetricValuesExtrapolation
  | LCEFacilityAndMetricIdentifiedMetricValues;

export type LCEFacilityAndMetricIdentifiedMetricValuesExtrapolation = {
  type: 'extrapolation';
  values: LCEFacilityAndMetricIdentifiedMetricValues[];
};

export type LCEFacilityAndMetricIdentifiedMetricValues =
  | {
      type: 'legal-entity';
      'identified-value': LCEAnyIdentifiedMetricValue;
    }
  | {
      type: 'property';
      'identified-values': LCEAnyIdentifiedMetricValue[];
    };

export type LCEBuiltInValues = {
  'facility-id': string;
  'sequence-id': string;
  'built-in-id': LCEBuiltInMetricId;
  'metric-interval-values': LCEBuiltInMetricIntervalValues;
};

export type LCEBuiltInMetricIntervalValues =
  | LCEBuiltInIdentifiedMetricValuesExtrapolation
  | LCEBuiltInIdentifiedMetricValues;

export type LCEBuiltInIdentifiedMetricValuesExtrapolation = {
  type: 'extrapolation';
  values: LCEBuiltInIdentifiedMetricValues[];
};

export type LCEBuiltInIdentifiedMetricValues = {
  type: 'built-in';
  'built-in-identifier': LCEBuiltInIdentifier;
  'built-in-value': LCEBuiltInValue;
};

export type LCEBuiltInValue =
  | {
      type: 'metric-value';
      'metric-value': LCEMetricValue | LCEMissingMetricValue | LCENotApplicableMetricValue;
    }
  | {
      type: 'interval-amounts';
      currency: string;
      'interval-amounts': {
        'value-date': string;
        'metric-value': LCEMetricValue | LCEMissingMetricValue;
      }[];
    };

export type LCEBuiltInIdentifier = {
  'facility-id': string;
  'sequence-id': string;
  'built-in-id': LCEBuiltInMetricId;
  interval: {
    period: InterestPeriodType;
    'start-date': string;
    'check-date': string;
  };
};

export type LCEFundMetricIntervalValues = LCEFundIdentifiedMetricValuesExtrapolation | LCEFundIdentifiedMetricValue;

export type LCEFundIdentifiedMetricValuesExtrapolation = {
  type: 'extrapolation';
  values: LCEFundIdentifiedMetricValue[];
};

export type LCEFundIdentifiedMetricValue = {
  type: 'fund';
  'identified-value': LCEAnyIdentifiedMetricValue;
};

export type LCEBreachLevel = 'skip' | 'missing' | 'not-applicable' | 'no-breach' | 'soft-breach' | 'hard-breach';

export const prettyLCEBreachLevel = (level: LCEBreachLevel) =>
  matchStringExhaustive(level, {
    skip: () => 'Skipped',
    missing: () => 'Missing',
    'not-applicable': () => 'Not Applicable',
    'no-breach': () => 'No Breach',
    'soft-breach': () => 'Soft Breach',
    'hard-breach': () => 'Hard Breach',
  });

export const prettyLCEInterval = (interval: { 'start-date': string; 'check-date': string }) => {
  const { 'start-date': startDate, 'check-date': checkDate } = interval;
  const start = parseIsoDate(startDate);
  const end = subDays(parseIsoDate(checkDate), 1);
  return prettyPeriod({ start, end });
};

export const prettyLCEBuiltIn = (x: LCEBuiltInMetricId) =>
  matchStringExhaustive(x, {
    interest: () => 'Interest',
    amortisation: () => 'Amortisation',
    'outstanding-debt': () => 'Outstanding Debt',
  });

export const prettyLCEReportedAgainst = (x: LCEReportedAgainst) =>
  matchStringExhaustive(x, {
    'legal-entity': () => 'Legal entity',
    property: () => 'Property',
  });

export const prettyLCERawMetricType = (x: LCERawMetric['type']) =>
  matchStringExhaustive(x, {
    interval: () => 'Span of Time',
    point: () => 'Point in Time',
  });

export const prettyLCEMetricUnit = (x: LCEMetricUnit) =>
  matchStringExhaustive(x, {
    number: () => 'Number',
    boolean: () => 'Boolean',
    ratio: () => 'Percentage',
    amount: () => 'Amount',
  });

export const prettyLCEFormulaOperator = (x: LCEFormulaOperator) => {
  return matchStringExhaustive(x, {
    div: () => '/',
    mul: () => '*',
    sub: () => '-',
    add: () => '+',
  });
};
