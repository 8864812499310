import * as Yup from 'yup';
import { ObjectStripUnknown, TextStripEmpty } from './atoms';
import { literal, sumTypeRequired } from './base';

const AccountValidation = ObjectStripUnknown({
  name: TextStripEmpty,
  owner: TextStripEmpty,
  bic: TextStripEmpty,
  iban: TextStripEmpty,
  'bank-name': TextStripEmpty,
});

const LenderAccountsValidation = Yup.lazy((accountsMap: object) => {
  return ObjectStripUnknown(Object.fromEntries(Object.keys(accountsMap).map(key => [key, AccountValidation])));
});

const DirectAccountsValidation = ObjectStripUnknown({
  'loan-service-account': AccountValidation,
  'lender-accounts': LenderAccountsValidation,
  'subvention-account': AccountValidation,
  'agent-fee-account': AccountValidation,
});

const DirectBookingModelValidation = ObjectStripUnknown({
  type: literal('direct'),
  accounts: DirectAccountsValidation,
});

const PayingAgentAccountsValidation = ObjectStripUnknown({
  'loan-service-account': AccountValidation,
  'payment-service-account': AccountValidation,
  'agent-income-account': AccountValidation,
  'agent-tax-withholding-account': AccountValidation,
  'lender-accounts': LenderAccountsValidation,
  'subvention-account': AccountValidation,
  'agent-fee-account': AccountValidation,
});

const PayingAgentBookingModelValidation = ObjectStripUnknown({
  type: literal('paying-agent'),
  accounts: PayingAgentAccountsValidation,
});

export const BookingModelValidation = sumTypeRequired('type', {
  direct: DirectBookingModelValidation,
  'paying-agent': PayingAgentBookingModelValidation,
});
