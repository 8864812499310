/**
 * Returns the list of duplicate elements in `xs`.
 */
export function duplicates<T>(xs: readonly T[]): T[] {
  const seen = new Set<T>();
  const duplicates = new Set<T>();
  for (const x of xs) {
    if (seen.has(x)) duplicates.add(x);
    seen.add(x);
  }
  return Array.from(duplicates);
}

/**
 * Iteration over the elements of an array in reverse order.
 */
export function* reverseIterator<T>(xs: T[]): Generator<T> {
  for (let i = xs.length - 1; i >= 0; i--) {
    yield xs[i];
  }
}

/**
 * Given f = isInArrayFn(xs) then f(x) is true if and only if xs.includes(x).
 */
export function isInArrayFn<T extends string | number>(xs: T[]): (x: T) => boolean {
  const map: Partial<Record<T, true>> = {};
  for (const x of xs) {
    map[x] = true;
  }
  return x => x in map;
}

export const splitIntoBatches = <T>(array: T[], batchSize: number): T[][] => {
  const batches: T[][] = [];
  const length = array.length;

  for (let i = 0; i < length; i += batchSize) {
    const batch = array.slice(i, i + batchSize);
    batches.push(batch);
  }

  return batches;
};

export const asyncMap = async <T, U>(arr: T[], fn: (t: T, index: number) => Promise<U>): Promise<U[]> => {
  const us: U[] = [];
  let i = 0;
  for (const t of arr) {
    us.push(await fn(t, i++));
  }
  return us;
};

/**
 * Make a filter function for uniqueness.
 * For reference equality consider {@link unique}.
 *
 * ```
 * const myArray = [{a: 1, b: ""}, {a: 1, b: "123"}, {a: 2, b: ""}];
 * const uniqueArray = myArray.filter(mkUnique((a, b) => a.a === b.a));
 * // [{a: 1, b: ""}, {a: 2, b: ""}]
 * ```
 */
export const mkUnique =
  <T>(eqFn: (v1: T, v2: T) => boolean) =>
  (v: T, i: number, arr: T[]) =>
    arr.findIndex(v2 => eqFn(v, v2)) === i;

/**
 * Filter function for uniqueness using reference equality.
 * For more complex equality consider {@link mkUnique}.
 *
 * ```
 * const myArray = [1, 1, 2, 3, 3];
 * const uniqueArray = myArray.filter(unique);
 * // [1, 2, 3]
 * ```
 */
export const unique = <T>(v: T, i: number, arr: T[]) => arr.indexOf(v) === i;
