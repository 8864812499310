import { BookingType } from './BookingType';
import { Drawdown } from './Drawdown';
import { InitiatePrepayment } from './InitiatePrepayment';
import { InitiatePrepaymentPayload } from './InitiatePrepaymentPayload';
import { InitiateSubperiodPrepayment } from './InitiateSubperiodPrepayment';

export type TransactionType =
  | PayOutPrincipalTransactionType
  | PayOutDrawdownTransaction
  | CouponTransactionType
  | PrepaymentTransactionType
  | SubperiodPrepaymentTransaction
  | PayBackPrincipalTransactionType;

export type TransactionStatusType =
  | 'outstanding' // All bookings are outstanding.
  | 'partial' // One or more booking has been instructed or settled.
  | 'settled'; // All bookings have been settled

export type ITransaction = {
  id: string;
  status: TransactionStatusType;
  currency: string;
  bookings: { [bookingId: string]: BookingType };
  'execution-date': string;
  'value-date': string;
};

export type PayOutPrincipalTransactionType = ITransaction & {
  type: 'pay-out-principal';
};

export type PayOutDrawdownTransaction = ITransaction & {
  type: 'pay-out-drawdown';
  'drawdown-id': string;
  details: {
    drawdown: Drawdown;

    // The initial commitment amount of the loan
    'commitment-amount': number;

    // The sum of all drawdowns made so far
    'total-drawdown-amount': number;

    // The available commitment or empty, if no more drawdowns are possible
    'available-commitment-amount'?: number;
  };
};

export type CouponTransactionType = ITransaction & {
  type: 'coupon';
  'period-index': number;
};

export type PrepaymentTransactionType = ITransaction & {
  type: 'prepayment';
  'period-index': number;
  details: {
    prepayment: InitiatePrepayment;

    // The data value that was set in the initiate-prepayment command. If the prepayment
    // was not initiated by a user command, the attribute will be undefined.
    'initiate-prepayment-data'?: InitiatePrepaymentPayload;
  };
};

export type SubperiodPrepaymentTransaction = ITransaction & {
  type: 'subperiod-prepayment';
  'subperiod-prepayment-value-date': string;
  details: {
    prepayment: InitiateSubperiodPrepayment;

    // The data value that was set in the initiate-prepayment command.
    'initiate-prepayment-data'?: InitiatePrepaymentPayload;
  };
};

export type PayBackPrincipalTransactionType = ITransaction & {
  type: 'pay-back-principal';
};

export const AllTransactionLabels: Record<TransactionType['type'], string> = {
  'pay-out-principal': 'Pay out principal',
  'pay-out-drawdown': 'Drawdown Payment',
  prepayment: 'On-Cycle prepayment',
  'subperiod-prepayment': 'Mid-Cycle prepayment',
  'pay-back-principal': 'Pay back principal',
  coupon: 'Interest and Amortisation Payment',
};
