import { defineApi } from '../common/api-definition';
import { ObjectStripUnknown } from '../validation/atoms';
import { NonEmptyString } from '../validation/base';
import { CommonLoanView } from './common-loan';
import { StructuredLegalEntityType } from './structured-agreement-type';

export type FacilityIntroView = {
  loanId: string;
  facilityId: string;
  legal_entity: StructuredLegalEntityType;
  commonView: CommonLoanView;
};

export type FacilityIntroRequest = {
  loanId: string;
  facilityId: string;
};

export const facilityIntroRequestValidation = ObjectStripUnknown<FacilityIntroRequest>({
  loanId: NonEmptyString.required(),
  facilityId: NonEmptyString.required(),
}).required();

export const facilityIntroApi = defineApi<{
  body: FacilityIntroRequest;
  response: FacilityIntroView;
}>({
  url: 'structured-loans/facility-intro-view',
  method: 'post',
});
